<template>
  <div class="mint" id="mint">
    <div class="header" id="headerPc">
      <div style="display: flex" v-if="decipherment">
        <img
            @click="miao1('#miao1')"
            class="logo"
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/logo.png"
            alt=""
        />
        <div
            class="title engreg"
            @click="miao1('#miao2')"
            style="margin-left: 60px"
        >
          LORE
        </div>
        <div class="title engreg" @click="miao1('#road2img')">ARTIST</div>
        <div class="title engreg" @click="miao1('#road4img')">ABOUT</div>
        <div class="title engreg" @click="miao1('#miao4')">ROADMAP</div>
        <div class="title engreg" @click="stake">STAKING</div>
        <div class="title engreg" @click="party">PARTY</div>
        <div class="title engreg" @click="world">GAMBLER HALL</div>
        <!-- <div class="title engreg" @click="changeCloth">World Cup Outfits</div> -->
        <div class="title engreg" @click="starry">3D PARTY</div>
      </div>
      <div style="display: flex" v-else>
        <img
            @click="miao1('#miao1')"
            class="logo"
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/logo.png"
            alt=""
        />
        <div
            class="title chinreg"
            @click="miao1('#miao2')"
            style="margin-left: 60px"
        >
          背景
        </div>
        <div class="title chinreg" @click="miao1('#road2img')">艺术家</div>
        <div class="title chinreg" @click="miao1('#road4img')">介绍</div>
        <div class="title chinreg" @click="miao1('#miao4')">路线图</div>
        <div class="title chinreg" @click="stake">质押</div>
        <div class="title chinreg" @click="party">PARTY</div>
        <div class="title chinreg" @click="world">世界杯赌王</div>
        <!-- <div class="title chinreg" @click="changeCloth">世界杯换装</div> -->
        <div class="title chinreg" @click="starry">3D PARTY</div>
      </div>
      <ul class="header_right">
        <li class="connect checktwo" style="display: none">
          <div @click="pass_status" class="hover_box2">
            <p class="engBold" v-if="decipherment">CHECK PASS STATUS</p>
            <p class="chinBold headermar" v-else>查查Pass卡状态</p>
          </div>
        </li>
        <li class="connect2" v-if="connected" style="display: none">
          <div v-if="status > 0">
            <p
                class="connect2_text engBold"
                v-if="available == 1"
                @click="ethbox"
            >
              MINT
            </p>
            <p
                class="connect2_text1 engBold"
                style="cursor: not-allowed"
                v-if="available == 0"
            >
              MINT
            </p>
          </div>
        </li>
        <li class="connect" v-if="connected" style="display: none">
          <div class="hover_box1 engBold">
            {{ walletAddress }}
            <div class="bai" v-if="CHECK_MY_STATUS == 0">
              <p class="engreg" v-if="decipherment">Yeah, no......</p>
              <p class="chinreg" v-else>后台名单没有你</p>
            </div>
            <div class="bai" v-if="CHECK_MY_STATUS == 1">
              <p class="engreg" v-if="decipherment">Yup, on the List</p>
              <p class="chinreg" v-else>后台名单里有你！</p>
            </div>
            <div class="bai" v-if="CHECK_MY_STATUS == 2">
              <p class="engreg" v-if="decipherment">Pass (Yes) List (No)</p>
              <p class="chinreg" v-else>你有Pass,但后台名单没有你</p>
            </div>
            <div class="bai" v-if="CHECK_MY_STATUS == 3">
              <p class="engreg" v-if="decipherment">Pass (Yes) List (Yes)</p>
              <p class="chinreg" v-else>你有Pass,还在后台名单上!</p>
            </div>
          </div>
        </li>
        <li class="connect1" v-else @click="logIn" style="display: none">
          <div v-if="status == 0">
            <p class="engBold" v-if="decipherment">Check Backstage</p>
            <p class="chinBold headermar" v-else>我在后台名单吗？</p>
          </div>
          <div v-else>
            <p class="engBold" v-if="decipherment">CONNECT WALLET</p>
            <p class="chinBold headermar" v-else>连接钱包</p>
          </div>
        </li>
        <li
            class="title1"
            @click="revise"
            :class="decipherment ? 'engreg' : 'chinreg'"
        >
          {{ eng }}
          <img src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/xia.png" alt=""/>
          <div class="cha_text" v-show="revise_text" @click="deciphermented">
            {{ chin }}
          </div>
        </li>
        <li class="img1" @click="openseaview">
          <img src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/opensea1.png" alt="" srcset=""/>
        </li>
        <li class="img">
          <img
              src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/twitter1.png"
              alt=""
              srcset=""
              @click="twitter"
          />
        </li>
        <li class="img">
          <img
              src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/redirect.png"
              alt=""
              srcset=""
              @click="redirect"
          />
        </li>
      </ul>
    </div>
    <div class="headerM" id="headerMM">
      <div>
        <img
            @click="miao1('#miao1')"
            class="logo"
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/logo.png"
            alt=""
        />
      </div>
      <div class="headerM_ul">
        <div class="checkpass" style="display: none">
          <div @click="pass_status" class="checktwo">
            <p class="engBold" v-if="decipherment">CHECK PASS</p>
            <p class="chinBold" v-else>查查Pass卡状态</p>
          </div>
        </div>
        <div class="butbox" style="display: none">
          <div class="connect" v-if="connected">
            <div class="hover_box1 engBold">
              {{ walletAddress }}
              <div class="bai" v-if="CHECK_MY_STATUS == 0">
                <p class="engreg" v-if="decipherment">Yeah, no......</p>
                <p class="chinreg" v-else>后台名单没有你</p>
              </div>
              <div class="bai" v-if="CHECK_MY_STATUS == 1">
                <p class="engreg" v-if="decipherment">Yup, on the List</p>
                <p class="chinreg" v-else>后台名单里有你！</p>
              </div>
              <div class="bai" v-if="CHECK_MY_STATUS == 2">
                <p class="engreg" v-if="decipherment">Pass (Yes) List (No)</p>
                <p class="chinreg" v-else>你有Pass,但后台名单没有你</p>
              </div>
              <div class="bai" v-if="CHECK_MY_STATUS == 3">
                <p class="engreg" v-if="decipherment">Pass (Yes) List (Yes)</p>
                <p class="chinreg" v-else>你有Pass,还在后台名单上!</p>
              </div>
            </div>
          </div>
          <div class="connect1" v-else @click="logIn">
            <div v-if="status == 0">
              <p class="engBold" v-if="decipherment">CHECK BACKSTAGE</p>
              <p class="chinBold" v-else>我在后台名单吗？</p>
            </div>
            <div v-else>
              <p class="engBold" v-if="decipherment">CONNECT</p>
              <p class="chinBold" v-else>连接钱包</p>
            </div>
          </div>
        </div>

        <!-- <img class="openseaM" src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/openseaM.png" alt="" />
        <img
          class="twitterM"
          src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/twitterM.png"
          alt=""
          @click="twitter"
        />
        <img
          class="twitterM"
          src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/redirect.png"
          alt=""
          @click="redirect"
        /> -->
        <div class="menubox" @click="menu">
          <img class="menu" src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/menu.png" alt=""/>
          <div class="del" v-if="menudel">
            <div v-if="decipherment">
              <p class="text engreg" @click="miao1('#miao2')">LORE</p>
              <p class="text engreg" @click="miao1('#road2img')">ARTIST</p>
              <p class="text engreg" @click="miao1('#road4img')">ABOUT</p>
              <p class="text engreg" @click="miao1('#miao5')">ROADMAP</p>
              <p class="text engreg" @click="party">PARTY</p>
              <p class="text engreg" @click="stake">STAKING</p>
              <p class="text engreg" @click="world">GAMBLE</p>
              <!-- <p class="text engreg" @click="changeCloth">OUTFITS</p> -->
              <p class="text engreg" @click="starry">3D PARTY</p>
              <p class="text engreg" @click="openseaview">
                <img src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/openseaM.png" alt="" srcset=""/>
                <span>OPENSEA</span>
              </p>
              <p class="text engreg" @click="twitter">
                <img src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/twitterM.png" alt="" srcset=""/>
                <span>TWITTER</span>
              </p>
              <p class="text engreg" @click="redirect">
                <img src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/redirect.png" alt="" srcset=""/>
                <span>DISCORD</span>
              </p>
              <p class="text engreg" @click="deciphermented">中文</p>
            </div>
            <div v-else>
              <p class="text chinreg" @click="miao1('#miao2')">背景</p>
              <p class="text chinreg" @click="miao1('#road2img')">艺术家</p>
              <p class="text chinreg" @click="miao1('#road4img')">介绍</p>
              <p class="text chinreg" @click="miao1('#miao5')">路线图</p>
              <p class="text chinreg" @click="party">PARTY</p>
              <p class="text chinreg" @click="stake">质押</p>
              <p class="text chinreg" @click="world">世界杯赌王</p>
              <!-- <p class="text chinreg" @click="changeCloth">世界杯换装</p> -->
              <p class="text chinreg" @click="starry">3D PARTY</p>
              <p class="text engreg" @click="openseaview">
                <img src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/openseaM.png" alt="" srcset=""/>
                <span>OPENSEA</span>
              </p>
              <p class="text engreg" @click="twitter">
                <img src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/twitterM.png" alt="" srcset=""/>
                <span>TWITTER</span>
              </p>
              <p class="text engreg" @click="redirect">
                <img src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/redirect.png" alt="" srcset=""/>
                <span>DISCORD</span>
              </p>
              <p class="text chinreg" @click="deciphermented">ENGLISH</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="banner" id="miao1" ref="headerScroll">
      <div class="walletImg">
        <img
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/openseabtn.png"
            @click="openseaview"
            class="gu"
        />
        <img src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/light.png" class="lights lightsdong"/>
      </div>
      <div class="walletImg" style="display: none">
        <div v-if="connected">
          <div v-if="status == -1">
            <img
                src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/openseabtn.png"
                @click="openseaview"
                class="gu"
            />
          </div>
          <div v-else-if="status == 0">
            <img
                v-if="decipherment"
                src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/notstarted.png"
                class="gu"
            />
            <img v-else src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/notstartedch.png"
                 class="gu"/>
          </div>
          <div v-else>
            <img
                src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/mintbtn.png"
                v-if="available == 1"
                @click="ethbox"
                class="gu"
            />
            <img
                src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/mintbtnfa.png"
                v-if="available == 0"
                class="gu"
                style="cursor: not-allowed"
            />
          </div>
        </div>
        <div v-else>
          <img src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/gu.png" class="gu" @click="logIn"/>
        </div>
        <img
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/light.png"
            class="lights"
            v-if="status == 0 && connected"
        />
        <img
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/light.png"
            class="lights lightsdong"
            v-else-if="!connected"
        />
        <img
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/light.png"
            class="lights lightsdong"
            ref="lights"
            v-else-if="available == 1"
        />
        <img
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/light.png"
            class="lights"
            ref="lights"
            v-else-if="available == 0"
        />
      </div>
      <div class="box">
        <img src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/box1.png"/>
        <div v-if="decipherment">
          <div class="content engreg">
            SOLD OUT! Better <br/>
            Check OpenSea.
          </div>
        </div>
        <div v-else>
          <div class="content chinreg">
            卖光了，去 <br/>
            Opensea看看吧！
          </div>
        </div>
      </div>
      <div style="display: none">
        <div class="box" v-if="connected">
          <img src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/box1.png"/>
          <div v-if="decipherment">
            <div class="content engreg" v-if="status == -1">
              SOLD OUT! Better <br/>
              Check OpenSea.
            </div>
            <div class="content engreg" v-else-if="status == 0">
              Mint Has Not <br/>
              Started Yet.
            </div>
            <div class="content2 engreg" v-else-if="status == 1">
              Everybody, <br/>Mint & Rock!<br/>(Inventory:
              {{ $store.state.order.orderInfo.stock }})
            </div>
            <div v-else-if="status == 4">
              <p class="content engreg" v-if="available == 0">
                Hol'Up, You're <br/>Not On The List!
              </p>
              <p
                  class="content engreg"
                  v-else-if="available == 1 && whiteListHolder == 1"
              >
                Yup, You're On The<br/>Backstage List!
              </p>
              <p
                  class="content engreg"
                  v-else-if="available == 1 && whiteListHolder == 0"
              >
                If It Ain't The <br/>
                OHDAT PASS Holder!
              </p>
            </div>
            <div v-else-if="status == 5">
              <p class="content engreg" v-if="available == 0">
                Hol'Up, You Don't <br/>
                Have The PASS!
              </p>
              <p class="content engreg" v-if="available == 1">
                If It Ain't The <br/>
                OHDAT PASS Holder!
              </p>
            </div>
          </div>
          <div v-else>
            <div class="content chinreg" v-if="status == -1">
              卖光了，去 <br/>
              Opensea看看吧！
            </div>
            <div class="content1 chinreg" v-else-if="status == 0">
              铸造还没开始
            </div>
            <div class="content chinreg" v-else-if="status == 1">
              还有{{
                $store.state.order.orderInfo.stock
              }}个空座儿，有<br/>戏，再挤挤
            </div>
            <div v-else-if="status == 4">
              <p class="content1 chinreg" v-if="available == 0">
                你没进后台，候着吧
              </p>
              <p
                  class="content chinreg"
                  v-else-if="available == 1 && whiteListHolder == 1"
              >
                燥，后台成员成功<br/>报到，快跟上！
              </p>
              <p
                  class="content chinreg"
                  v-else-if="available == 1 && whiteListHolder == 0"
              >
                哟,欢迎OHDAT老炮儿,<br/>给您留了座儿,来吧
              </p>
            </div>
            <div v-else-if="status == 5">
              <p class="content chinreg" v-if="available == 0">
                你没有OHDAT Pass!<br/>咱讲究点
              </p>
              <p class="content chinreg" v-if="available == 1">
                哟,欢迎OHDAT老炮儿,<br/>给您留了座儿,来吧
              </p>
            </div>
          </div>
        </div>
        <div class="box" v-else>
          <img src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/box1.png"/>
          <div class="content engreg" v-if="decipherment">
            BA DUM TSS to<br/>
            Connect Wallet
          </div>
          <div class="content chinreg" v-else>你你你你 <br/>要连钱包吗？</div>
        </div>
      </div>
      <video
          autoplay
          loop
          muted
          width="100%"
          webkit-playsinline="true"
          playsinline="true"
          class="banner_video"
          poster="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/gfdfrfr.png"
      >
        <source src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/gfdfrfr.mp4" type="video/mp4"/>
      </video>
      <div class="mbanner">
        <img
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/banner.png"
            alt=""
            srcset=""
            class="banner_videoM"
        />
        <div class="walletImgs">
          <div>
            <img
                src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/openseabtn.png"
                @click="openseaview"
                class="gu"
            />
          </div>
          <img src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/light.png" class="lights lightsdong"/>
        </div>
        <div class="walletImgs" style="display: none">
          <div v-if="connected">
            <div v-if="status == -1">
              <img
                  src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/openseabtn.png"
                  @click="openseaview"
                  class="gu"
              />
            </div>
            <div v-else-if="status == 0">
              <img
                  v-if="decipherment"
                  src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/notstarted.png"
                  class="gu"
              />
              <img v-else src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/notstartedch.png"
                   class="gu"/>
            </div>
            <div v-else>
              <img
                  src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/mintbtnfa.png"
                  v-if="available == 0"
                  class="gu"
                  style="cursor: not-allowed"
              />
              <img
                  src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/mintbtn.png"
                  v-if="available == 1"
                  @click="ethbox"
                  class="gu"
              />
            </div>
          </div>
          <div v-else>
            <img src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/gu.png" class="gu" @click="logIn"/>
          </div>
          <img
              src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/light.png"
              class="lights"
              v-if="status == 0 && connected"
          />
          <img
              src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/light.png"
              class="lights lightsdong"
              v-else-if="!connected"
          />
          <img
              src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/light.png"
              class="lights lightsdong"
              ref="lights"
              v-else-if="available == 1"
          />
          <img
              src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/light.png"
              class="lights"
              ref="lights"
              v-else-if="available == 0"
          />
        </div>
      </div>
      <img
          src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/down.png"
          alt=""
          srcset=""
          class="banner_down"
      />
    </div>
    <div class="road" id="miao2" ref="roadScroll">
      <div class="roadbox">
        <div class="road_left">
          <div class="road_left_text" id="road_left_text">
            <div v-if="decipherment">
              <p class="text_title engBold">{{ title_text }}</p>
              <p class="text_title engBold">{{ title_text1 }}</p>
              <p class="text_body engreg">
                {{ title_text2 }}
              </p>
            </div>
            <div v-else>
              <p class="text_title chinBold">{{ title_text }}</p>
              <p class="text_title chinBold">{{ title_text1 }}</p>
              <p class="text_body chinreg">
                {{ title_text2 }}
              </p>
            </div>
          </div>
        </div>
        <div class="road_right">
          <div class="road_rightimg">
            <img
                class="disimg"
                src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/road.png"
                alt=""
                srcset=""
            />
            <img
                class="disimgM"
                src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/roadM.png"
                alt=""
                srcset=""
            />
            <div :class="direction?'duckimg':'duckimg1'" id="duckimg"></div>
            <!-- <img
              class="duckimg"
              id="duckimg"
              src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/duck.png"
              alt=""
              srcset=""
              v-if="direction"
            />
            <img
              class="duckimg"
              id="duckimg"
              src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/duckright.png"
              alt=""
              srcset=""
              v-else
            />       -->
            <div class="road1" ref="road1">
              <img
                  class="road_img"
                  src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/road6.png"
                  alt=""
                  srcset=""
              />
            </div>
            <div class="road2" ref="road2" id="road2img">
              <img
                  class="road_img"
                  src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/road5.png"
                  alt=""
                  srcset=""
              />
              <img
                  @click="road_del1(3)"
                  class="road_del"
                  src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/jia.png"
                  alt=""
              />
            </div>
            <div class="road3" ref="road3">
              <img
                  class="road_img"
                  src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/road3.png"
                  alt=""
                  srcset=""
              />
              <img
                  @click="road_del1(1)"
                  class="road_del"
                  src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/jia.png"
                  alt=""
              />
            </div>
            <div class="road4" ref="road4">
              <img
                  class="road_img"
                  src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/road2.png"
                  alt=""
                  srcset=""
              />
              <img
                  @click="road_del1(4)"
                  class="road_del"
                  src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/jia.png"
                  alt=""
              />
            </div>
            <div class="road5" ref="road5" id="road4img">
              <img
                  class="road_img"
                  src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/road4.png"
                  alt=""
                  srcset=""
              />
              <img
                  @click="road_del1(2)"
                  class="road_del"
                  src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/jia.png"
                  alt=""
              />
            </div>
            <div class="road6" ref="road6">
              <img
                  class="road_img"
                  src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/road1.png"
                  alt=""
                  srcset=""
              />
              <img
                  @click="road_del1(5)"
                  class="road_del"
                  src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/jia.png"
                  alt=""
              />
            </div>
            <img
                class="black_hole"
                ref="hole"
                src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/black_hole.png"
                alt=""
                srcset=""
                v-if="black_hole_img"
            />
            <img
                class="villain_red"
                id="villain_red"
                src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/villain_red.png"
                alt=""
                srcset=""
            />
            <img
                class="villain_blue"
                id="villain_blue"
                src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/villain_blue.png"
                alt=""
                srcset=""
            />
          </div>
        </div>
      </div>
    </div>
    <div class="xingxing" id="miao3">
      <!-- <img
        class="xingxingimg"
        src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/xingxing.png"
        alt=""
        srcset=""
      /> -->
      <video
          autoplay
          loop
          muted
          width="100%"
          webkit-playsinline="true"
          playsinline="true"
          class="video3"
      >
        <source src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/fffffff.mp4" type="video/mp4"/>
      </video>
    </div>
    <div class="meteor" id="miao8" ref="meteorScroll">
      <video
          autoplay
          loop
          muted
          width="100%"
          webkit-playsinline="true"
          playsinline="true"
          class="video1"
      >
        <source src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/dffd.mp4" type="video/mp4"/>
      </video>
      <video
          autoplay
          loop
          muted
          width="100%"
          webkit-playsinline="true"
          playsinline="true"
          class="video6"
      >
        <source src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/ffrefrf.mp4" type="video/mp4"/>
      </video>
      <div class="carallbox">
        <img class="meteorimg" :src="meteorimg" alt="" srcset=""/>
        <img
            class="meteorimg1"
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/meteor9.gif"
            alt=""
            srcset=""
        />
        <img
            class="carall"
            id="car_all"
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/carall1.gif"
            alt=""
            srcset=""
        />
      </div>
    </div>
    <div class="islands" ref="islandsScroll">
      <video
          autoplay
          loop
          muted
          width="100%"
          webkit-playsinline="true"
          playsinline="true"
          class="video2"
          id="miao4"
      >
        <source src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/ewdewdd1.mp4" type="video/mp4"/>
      </video>
      <img class="ti_img" id="miao5" src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/title.gif"
           alt=""/>
      <!-- <img class="ti_imgM" src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/title.gif" alt="" /> -->
      <img class="islands_img" src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/islands.png" alt=""/>
      <img class="islands_imgM" src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/islandsM.png"
           alt=""/>
      <div class="islandsboxM">
        <div
            :class="ii == 1 ? 'highlight_trl highlight1' : 'highlight_trl'"
            @click="highlightall(1)"
        >
          <img src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/highlight1.png" alt="" srcset=""/>
        </div>
        <div
            :class="ii == 2 ? 'highlight_art highlight2' : 'highlight_art'"
            @click="highlightall(2)"
        >
          <img src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/highlight2.png" alt="" srcset=""/>
        </div>
        <div
            :class="ii == 3 ? 'highlight_music highlight3' : 'highlight_music'"
            @click="highlightall(3)"
        >
          <img src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/highlight3.png" alt="" srcset=""/>
        </div>
        <div
            :class="ii == 4 ? 'highlight_verse highlight4' : 'highlight_verse'"
            @click="highlightall(4)"
        >
          <img src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/highlight4.png" alt="" srcset=""/>
        </div>
        <div
            :class="
            ii == 5 ? 'highlight_machine highlight5' : 'highlight_machine'
          "
            @click="highlightall(5)"
        >
          <img src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/highlight5.png" alt="" srcset=""/>
        </div>
        <div
            :class="ii == 6 ? 'highlight_fast highlight6' : 'highlight_fast'"
            @click="highlightall(6)"
        >
          <img src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/highlight6.png" alt="" srcset=""/>
        </div>
        <div
            :class="ii == 7 ? 'highlight_you highlight7' : 'highlight_you'"
            @click="highlightall(7)"
        >
          <img src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/highlight7.png" alt="" srcset=""/>
        </div>
        <div
            :class="ii == 8 ? 'highlight_car highlight8' : 'highlight_car'"
            @click="highlightall(8)"
        >
          <img src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/highlight8.png" alt="" srcset=""/>
        </div>
        <div
            :class="ii == 9 ? 'highlight_cen highlight9' : 'highlight_cen'"
            @click="highlightall(9)"
        >
          <img src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/highlight9.png" alt="" srcset=""/>
        </div>
        <div
            :class="ii == 10 ? 'highlight_left highlight10' : 'highlight_left'"
            @click="highlightall(10)"
        >
          <img src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/highlight10.png" alt="" srcset=""/>
        </div>
        <div
            :class="ii == 11 ? 'highlight_right highlight11' : 'highlight_right'"
            @click="highlightall(11)"
        >
          <img src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/highlight11.png" alt="" srcset=""/>
        </div>
      </div>
      <div class="islandsboxPC">
        <div
            class="highlight_trl"
            @click="highlightall(1)"
            @mouseenter="mouseover"
            @mouseleave="mouseLeave"
        ></div>
        <img
            class="highlight1_img"
            v-show="highlight_irl"
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/highlight1.png"
            alt=""
            srcset=""
        />
        <div
            class="highlight_art"
            @mouseenter="mouseover1"
            @mouseleave="mouseLeave1"
            @click="highlightall(2)"
        ></div>
        <img
            class="highlight2_img"
            v-show="highlight_art"
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/highlight2.png"
            alt=""
            srcset=""
        />
        <div
            class="highlight_music"
            @mouseenter="mouseover2"
            @mouseleave="mouseLeave2"
            @click="highlightall(3)"
        ></div>
        <img
            class="highlight3_img"
            v-show="highlight_music"
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/highlight3.png"
            alt=""
            srcset=""
        />
        <div
            class="highlight_verse"
            @mouseenter="mouseover3"
            @mouseleave="mouseLeave3"
            @click="highlightall(4)"
        ></div>
        <img
            class="highlight4_img"
            v-show="highlight_verse"
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/highlight4.png"
            alt=""
            srcset=""
        />
        <div
            class="highlight_machine"
            @mouseenter="mouseover4"
            @mouseleave="mouseLeave4"
            @click="highlightall(5)"
        ></div>
        <img
            class="highlight5_img"
            v-show="highlight_machine"
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/highlight5.png"
            alt=""
            srcset=""
        />
        <div
            @mouseenter="mouseover5"
            @mouseleave="mouseLeave5"
            class="highlight_fast"
            @click="highlightall(6)"
        ></div>
        <img
            class="highlight6_img"
            v-show="highlight_fast"
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/highlight6.png"
            alt=""
            srcset=""
        />
        <div
            class="highlight_you"
            @mouseenter="mouseover6"
            @mouseleave="mouseLeave6"
            @click="highlightall(7)"
        ></div>
        <img
            class="highlight7_img"
            v-show="highlight_you"
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/highlight7.png"
            alt=""
            srcset=""
        />
        <div
            class="highlight_car"
            @mouseenter="mouseover7"
            @mouseleave="mouseLeave7"
            @click="highlightall(8)"
        ></div>
        <img
            class="highlight8_img"
            v-show="highlight_car"
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/highlight8.png"
            alt=""
            srcset=""
        />
        <div class="highlight_cen" @click="highlightall(9)">
          <img src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/highlight9.png" alt="" srcset=""/>
        </div>
        <div class="highlight_left" @click="highlightall(10)">
          <img src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/highlight10.png" alt="" srcset=""/>
        </div>
        <div class="highlight_right" @click="highlightall(11)">
          <img src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/highlight11.png" alt="" srcset=""/>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="footer_text">
        <span @click="terms" style="cursor: pointer">
          Terms and Conditions<br
        /></span>
        <span>© 2022 Peking Monsters. All Right Reserved.</span>
        <img
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/twitter1.png"
            alt=""
            srcset=""
            @click="twitter"
            style="cursor: pointer"
        />
        <img src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/opensea1.png" alt="" srcset=""/>
      </div>
    </div>
    <!-- road详情 -->
    <div class="priceBox" v-show="road1_ku">
      <div class="imgbg">
        <img class="closeall" :src="road_cha" alt=""/>
        <img
            class="close"
            @click="close_record"
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/road_cha.png"
            alt=""
        />
        <div class="del_content">
          <div v-if="decipherment">
            <p class="del_title engBold">{{ road1_kutitle }}</p>
            <p class="del_title engBold">{{ road1_kutitle1 }}</p>
            <p class="del_text engreg del_texttop">{{ road1_kutext }}</p>
            <p class="del_text engreg">{{ road1_kutext2 }}</p>
            <p class="del_text engreg">{{ road1_kutext3 }}</p>
            <p class="del_text engreg">{{ road1_kutext4 }}</p>
          </div>
          <div v-else>
            <p class="del_title chinBold">{{ road1_kutitle }}</p>
            <p class="del_title chinBold">{{ road1_kutitle1 }}</p>
            <p class="del_text chinreg del_texttop">{{ road1_kutext }}</p>
            <p class="del_text chinreg">{{ road1_kutext2 }}</p>
            <p class="del_text chinreg">{{ road1_kutext3 }}</p>
            <p class="del_text chinreg">{{ road1_kutext4 }}</p>
          </div>

          <p class="biao" v-if="biaozhu">
            <img src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/biao.png" alt="" srcset=""/>
          </p>
        </div>
      </div>
    </div>
    <!-- Roadmap详情 -->
    <div class="priceBox" v-show="roadmap_ku">
      <div class="imgbg1">
        <img
            class="close"
            @click="close_record"
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/roadmap_cha.png"
            alt=""
        />
        <div class="del_content">
          <div v-if="decipherment">
            <p class="del_title engBold">{{ roadmap_kutitle }}</p>
            <p class="del_title1 engBold">{{ roadmap_kutitle1 }}</p>
            <p class="del_text engreg">{{ roadmap_kutext }}</p>
          </div>
          <div v-else>
            <p class="del_title chinBold">{{ roadmap_kutitle }}</p>
            <p class="del_title1 chinBold">{{ roadmap_kutitle1 }}</p>
            <p class="del_text chinreg">{{ roadmap_kutext }}</p>
          </div>
        </div>
      </div>
    </div>
    <!-- ETH MINT -->
    <div class="priceBox" v-show="mintbox">
      <div
          :class="
          contract_method == 'pass_mint' || Afterclicking
            ? 'imgbgtop1'
            : 'imgbgtop'
        "
          class="imgbg2"
      >
        <img
            class="close"
            @click="close_record"
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/tan_cha.png"
            alt=""
        />
        <div class="del_content1">
          <div>
            <div v-if="contract_method == 'pass_mint'">
              <div class="passKa" v-if="Afterclicking == false">
                <div class="passtext">
                  <p class="engreg" v-if="decipherment">
                    THE OHDAT GENESIS PASS YOU ARE USING IS
                  </p>
                  <p class="chinreg" v-else>您选择使用的OHDAT GENESIS PASS为</p>
                </div>
                <div class="passxia" v-if="!passxia">
                  <p class="passId">#{{ passimg }}</p>
                  <div class="passre">
                    <p class="engreg" v-if="decipherment">
                      (Mints left:{{ limit }})
                    </p>
                    <p class="chinreg" v-else>(还能mint {{ limit }}个)</p>
                  </div>
                  <p>
                    <img
                        class="xiajiao1"
                        @click="passxiala"
                        src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/xiajiao.png"
                        alt=""
                        srcset=""
                        v-if="decipherment"
                    />
                    <img
                        class="xiajiao"
                        @click="passxiala"
                        src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/xiajiao.png"
                        alt=""
                        srcset=""
                        v-else
                    />
                  </p>
                </div>
                <div class="passxiala" v-else>
                  <div class="passxiala_top">
                    <p class="passId" style="color: #b8fff4">#{{ passimg }}</p>
                    <div class="passre" style="color: #b8fff4">
                      <p class="engreg" v-if="decipherment">
                        (Mints left:{{ limit }})
                      </p>
                      <p class="chinreg" v-else>(还能mint {{ limit }}个)</p>
                    </div>
                    <p>
                      <img
                          class="xiajiao1"
                          @click="passxiala"
                          src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/xiajiao.png"
                          alt=""
                          srcset=""
                          v-if="decipherment"
                      />
                      <img
                          class="xiajiao"
                          @click="passxiala"
                          src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/xiajiao.png"
                          alt=""
                          srcset=""
                          v-else
                      />
                    </p>
                  </div>
                  <div class="xiala_box">
                    <div
                        class="passxiala_top1"
                        @click="pass_list_tab(item)"
                        v-for="(item, key) in pass_list"
                        :key="key"
                        v-show="item.id != passimg"
                    >
                      <!-- <p
                        class="chapass passxiala_top2"
                        v-if="item.purchaseLimit == 0"
                      >
                        <img src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/passcha2.png" alt="" />
                      </p> -->
                      <p
                          class="passId1 passxiala_top2"
                          :class="item.purchaseLimit == 0 ? 'colore' : ''"
                      >
                        #{{ item.id }}
                      </p>
                      <div
                          class="passre1"
                          :class="item.purchaseLimit == 0 ? 'colore' : ''"
                      >
                        <p class="engreg" v-if="decipherment">
                          (Mints left:{{ item.purchaseLimit }})
                        </p>
                        <p class="chinreg" v-else>
                          (还能mint {{ item.purchaseLimit }}个)
                        </p>
                      </div>
                      <div class="yin"></div>
                    </div>
                  </div>
                </div>
              </div>
              <p class="del_img1" v-if="Afterclicking == true">
                <img src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/carall.gif" alt="" srcset=""/>
              </p>
            </div>
            <div v-else>
              <p class="del_img" v-if="Afterclicking == false">
                <img src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/carall1.gif" alt="" srcset=""/>
              </p>
              <p class="del_img1" v-if="Afterclicking == true">
                <img src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/carall.gif" alt="" srcset=""/>
              </p>
            </div>
          </div>
          <div class="del_title" v-if="Afterclicking == false">
            <p class="del_text engreg" v-if="decipherment">Price</p>
            <p class="del_text chinreg" v-else>售价:</p>
            <p class="del_num engreg">{{ ethPrice }} ETH</p>
          </div>
          <div class="del_title" v-if="Afterclicking == false">
            <p class="del_text engreg" v-if="decipherment">Quantity</p>
            <p class="del_text chinreg" v-else>数量:</p>
            <p class="del_num1">
              <img
                  class="tan_jian"
                  @click="tanjian"
                  src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/tan_jian1.png"
                  alt=""
                  v-if="tannum > 1 && limit != 1 && tannum <= limitbig"
              />
              <img
                  class="tan_jian"
                  @click="tanjian"
                  src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/tan_jian.png"
                  alt=""
                  v-if="tannum == 1 && limit != 1"
              />
              <span class="spantext1 engreg" v-if="limit == 1">{{
                  tannum
                }}</span>
              <span class="spantext1 engreg" v-if="limit == 0">{{
                  tannum
                }}</span>
              <span class="spantext engreg" v-if="limit > 1">{{ tannum }}</span>
              <img
                  class="tan_jian"
                  @click="tanjia"
                  src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/tan_jia1.png"
                  alt=""
                  v-if="tannum == limitbig && limit != 1"
              />
              <img
                  class="tan_jian"
                  @click="tanjia"
                  src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/tan_jia.png"
                  alt=""
                  v-if="tannum >= 1 && limit != 1 && tannum < limitbig"
              />
            </p>
          </div>
          <div class="del_title" v-if="Afterclicking == false">
            <p class="del_text engreg" v-if="decipherment">Total</p>
            <p class="del_text chinreg" v-else>总价:</p>
            <p class="del_num engreg">
              <img src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/tan_eth.png" alt="" srcset=""/>
              <span>{{ totalPrice }}</span>
            </p>
          </div>
          <div class="lave" v-if="Afterclicking == false">
            <p class="engreg" v-if="decipherment">
              Inventory: {{ $store.state.order.orderInfo.stock }}
            </p>
            <p class="chinreg" v-else>
              库存: {{ $store.state.order.orderInfo.stock }}
            </p>
          </div>
          <div
              v-if="viewonop == true && Notclickable == false"
              class="mintsucc"
          >
            <p class="engreg" v-if="decipherment">SUCCESS!</p>
            <p class="chinreg" v-else>Mint成功！</p>
          </div>
          <div>
            <div class="btn2" v-if="tannum == 0">
              <p class="engreg btn2_eng" v-if="decipherment">Limit reached</p>
              <p class="chinreg" v-else>达到购买上限</p>
            </div>
            <div v-else-if="Notclickable == true && viewonop == false">
              <div
                  class="btn"
                  v-if="contract_method == 'white_list_mint'"
                  @click="whiteList"
              >
                <p class="engreg" v-if="decipherment">MINT</p>
                <p class="engreg" v-else>MINT</p>
              </div>
              <div
                  class="btn"
                  v-if="contract_method == 'pass_mint'"
                  @click="passList"
              >
                <p class="engreg" v-if="decipherment">MINT</p>
                <p class="engreg" v-else>MINT</p>
              </div>
              <div
                  class="btn"
                  v-if="contract_method == 'mint'"
                  @click="mintList"
              >
                <p class="engreg" v-if="decipherment">MINT</p>
                <p class="engreg" v-else>MINT</p>
              </div>
            </div>
            <div
                class="btn1"
                v-else-if="Notclickable == false && viewonop == false"
            >
              <p class="engreg" v-if="decipherment">PROCESSING...</p>
              <p class="chinreg" v-else>交易进行中...</p>
            </div>

            <div
                class="btn"
                v-else-if="viewonop == true && Notclickable == false"
                @click="openseaview"
            >
              <p class="engreg" v-if="decipherment">View on OpenSea</p>
              <p class="chinreg" v-else>
                <span>去OpenSea看看</span>
                <img
                    class="btnimg"
                    src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/openseaM.png"
                    alt=""
                    srcset=""
                />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- PassKa -->
    <div class="PassKa" v-show="PassKa">
      <div class="PassKabig">
        <div class="PassKabg">
          <img
              class="close"
              @click="close_record1"
              src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/tan_cha.png"
              alt=""
          />
          <div class="passxuan">
            <p class="engreg" v-if="decipherment">
              Select the Pass you want to use
            </p>
            <p class="chinreg" v-else>选择您想使用的Pass</p>
          </div>
          <div class="PassKalist">
            <div
                class="PassKalist_box"
                v-for="(item, key) in pass_list"
                :key="key"
            >
              <img @click="imgxuanze(item.id)" :src="item.url.url" alt=""/>
              <img
                  v-if="item.purchaseLimit == 0"
                  class="passcha"
                  src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/passcha2.png"
                  alt=""
                  srcset=""
              />
              <p class="bianhao engreg">#{{ item.id }}</p>
              <div>
                <p class="shangyu engreg" v-if="decipherment">
                  (Inventory: {{ item.purchaseLimit }})
                </p>
                <p class="shangyu chinreg" v-else>
                  (剩余: {{ item.purchaseLimit }})
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- CHECK WHITELIST -->
    <div class="priceBox1" v-show="checkbox">
      <div
          class="imgbg"
          :class="pass_holder == 1 ? 'imgbgcheck' : 'imgbgcheck1'"
      >
        <img
            class="close"
            @click="close_record"
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/tan_cha.png"
            alt=""
        />
        <img class="checkcha" :src="checkchaimg" alt=""/>
        <div class="checktext engreg">
          <div v-if="white_list_holder == 0 && pass_holder == 0">
            <p class="del_text engreg" v-if="decipherment">
              Uh-oh. You're not eligible for Pass Holder Mint and Backstage List
              Mint. Come back for Public Mint!
            </p>
            <p class="del_text chinreg" v-else>
              不好意思，咱Pass或后台哪个都没有
            </p>
          </div>
          <div v-if="white_list_holder == 1 && pass_holder == 0">
            <p class="del_text engreg" v-if="decipherment">
              Congrats! You're eligible for Backstage List Mint.
            </p>
            <p class="del_text chinreg" v-else>
              恭喜！您的后台资格已符合，但Pass资格未被验证
            </p>
          </div>
          <div v-if="white_list_holder == 0 && pass_holder == 1">
            <p class="del_text engreg" v-if="decipherment">
              Congrats! You're eligible for Pass Holder Mint.
            </p>
            <p class="del_text chinreg" v-else>
              牛逼！您尊贵的Pass身份已被验证，要再有个后台就更完美了
            </p>
          </div>
          <div v-if="white_list_holder == 1 && pass_holder == 1">
            <p class="del_text engreg" v-if="decipherment">
              Congrats! You're eligible for Pass Holder Mint and Backstage List
              Mint.
            </p>
            <p class="del_text chinreg" v-else>
              牛逼！您不仅有尊贵的Pass还有后台名额
            </p>
          </div>
        </div>
        <div class="checktext1 engreg" v-if="pass_holder == 1">
          <p class="del_text engreg" v-if="decipherment">
            OHDAT Genesis Pass List:
          </p>
          <p class="del_text chinreg" v-else>你的Pass列表:</p>
        </div>
        <div class="PassKalist" v-if="pass_holder == 1">
          <div
              class="PassKalist_box"
              v-for="(item, key) in pass_list1"
              :key="key"
          >
            <img @click="imgxuanze(item.id)" :src="item.url.url" alt=""/>
            <img
                v-if="item.purchaseLimit == 0"
                class="passcha"
                src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/passcha2.png"
                alt=""
                srcset=""
            />
            <p class="bianhao engreg">#{{ item.id }}</p>
            <div>
              <p class="shangyu engreg" v-if="decipherment">
                (Inventory: {{ item.purchaseLimit }})
              </p>
              <p class="shangyu chinreg" v-else>
                (剩余: {{ item.purchaseLimit }})
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- pass_status_submit -->
    <div class="priceBox1" v-show="inquire">
      <div class="imgbg_inquire">
        <img
            class="close"
            @click="close_record"
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/tan_cha.png"
            alt=""
        />
        <div>
          <div class="engreg" v-if="decipherment">
            <p class="inquier_text inquier_texttop engreg">
              Enter OHDAT Genesis Pass ID
            </p>
            <p class="inquier_text engreg">to check how much you can mint.</p>
          </div>
          <div class="chinreg" v-else>
            <p class="inquier_text inquier_texttop chinreg">输入Pass ID，</p>
            <p class="inquier_text chinreg">检查这个Pass还能mint的数量</p>
          </div>
        </div>

        <div class="inquier_box">
          <div>
            <div class="inquier_input">
              <input @input="inquirechange" type="text" v-model="inquireText"/>
            </div>
          </div>
          <div class="inquier_btn" @click="pass_status_submit">
            <img src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/submit_inquire.png" alt=""/>
          </div>
        </div>
        <div class="inquier_Remaining" v-if="Remainingtrue">
          <p class="engreg" v-if="decipherment">
            Mint amount left: {{ inquireRemaining }}
          </p>
          <p class="chinreg" v-else>
            当前该Pass可mint数量为: {{ inquireRemaining }}
          </p>
        </div>
      </div>
    </div>
    <video
        :src="musicSrc"
        controls="controls"
        ref="initAudio"
        class="initAudio"
    ></video>
    <!-- music -->
    <div class="music">
      <div class="loadplay" v-if="load">
        <img src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/loadplay.png"/>
        <div class="gifImg">
          <img src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/earth.gif"/>
        </div>
        <div class="play" @click="playMusic" v-if="loadplay"></div>
        <p class="addOpen" v-else @click="addOpen">
          <img src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/open.png"/>
        </p>
        <img src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/bo.gif" class="linem"
             v-if="startAction"/>
        <img src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/stoppiont.png" class="stoppoint"
             v-else/>
      </div>
      <div class="playLand" v-else>
        <div class="gifImg">
          <img src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/earth.gif"/>
        </div>
        <img src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/bo.gif" class="linem"
             v-if="startAction"/>
        <img src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/stoppiont.png" class="stoppoint"
             v-else/>
        <div class="musicName chinreg" ref="musicName">
          <marquee behavior="scroll" scrollamount="2" ref="singerNamed">
            <div class="top">{{ singerNames }}</div>
            <div>{{ singers }}</div>
          </marquee>
          <!-- <marquee behavior="scroll"  scrollamount = 2>
            {{singers}}
          </marquee> -->
        </div>
        <div class="musicBtn">
          <div class="up" @click="up">
            <img src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/up.png"/>
          </div>
          <div class="control">
            <img
                src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/stop.png"
                v-if="stop"
                @click="playMusic"
            />
            <img src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/action.png" @click="stopplay"
                 v-else/>
          </div>
          <div class="next" @click="next">
            <img src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/next.png"/>
          </div>
        </div>
        <img src="https://img.ohdat.io/peking_monsters/website/assets/icon/mint/shou.png" class="shou" @click="shou"/>
      </div>
    </div>
  </div>
</template>

<script>
const {ethereum} = window;
import {
  place_goods_order_submit,
  place_goods_order,
} from "@/api/trade/index.js";
import {
  connectWallet,
  getCurrentAccount,
  walletSign,
  checkChain,
} from "@/utils/login.js";
// import domtoimage from "dom-to-image";
import {
  get_goods_datail,
  get_purchase_options,
  check_wallet_status,
  check_pass_status,
} from "@/api/goods/index.js";

const web3 = require("web3");
import {whiteListMint, passMint, Mint} from "@/utils/web3.js";
import {Message} from "element-ui";

export default {
  name: "mint",
  data() {
    return {
      i: "",
      roadDom: "",
      meteorDom: "",
      road1: "",
      road3: "",
      road5: "",
      hole: "",
      DuckImgTop: 0,
      DuckImgLeft: 0,
      DuckImgscale: 0,
      VillainRedscale: 0,
      VillainBluescale: 0,
      VillainRedrotate: 0,
      VillainBluerotate: 0,
      VillainRedLeft: 0,
      VillainRedTop: 0,
      VillainBlueLeft: 0,
      direction: false,
      title_text: "Peking Monsters",
      title_text1: "Express",
      title_text2:
          "Testing, 123, this is your trip guide speaking. We are a party of 2777 monsters heading to the Rock³verse in the Ethereum blockchain. The world may be in lockdown, but the show DOES NOT stop, so unbuckle those safety belts, chug that Remaining alcohol, and put on your predator sunglasses. It's gonna be a rough rough ride.",
      meteor_img: true,
      black_holeWid: 24,
      black_holeRig: 13,
      black_holeBot: 1.7,
      islandsS: "",
      black_hole_img: true,
      hole_imgtru: true,
      carallsca: 0.2,
      caralltop: -400,
      carallleft: 0,
      caralltra: false,
      carallture: true,
      road1_ku: false,
      road1_kutitle: "Trip Guide:",
      road1_kutitle1: "Peng Lei",
      road1_kutext2: "",
      road1_kutext3: "",
      road1_kutext4: "",
      road1_kutext:
          "Peng Lei, cartoonist, director, vocalist and guitarist of New Pants, author of graphic novel Peking Monster. With a strong love for high contrast and genre intermixture, he underlines his notion of an idealistic world beneath realistic constructions. Same as his ever eccentric works, this project is a blender of whirling visual and audio sensations.",
      road_cha: "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/road_cha1.png",
      roadmap_ku: false,
      roadmap_kutitle: "IRL",
      roadmap_kutitle1: "",
      roadmap_kutext:
          "The Rock³verse experience reaches out to the physical world. Our series of offline activities endow holders with numerous offline benefits including exclusive access to Peng’s pop toy collectibles and concert ticket airdrops.",
      bannerimg: true,
      decipherment: true,
      chin: "中文",
      eng: "ENGLISH",
      revise_text: false,
      highList: [
        {
          id: 1,
          name: "highlight1",
        },
        {
          id: 2,
          name: "highlight2",
        },
        {
          id: 3,
          name: "highlight3",
        },
        {
          id: 4,
          name: "highlight4",
        },
        {
          id: 5,
          name: "highlight5",
        },
        {
          id: 6,
          name: "highlight6",
        },
        {
          id: 7,
          name: "highlight7",
        },
        {
          id: 8,
          name: "highlight8",
        },
        {
          id: 9,
          name: "highlight9",
        },
        {
          id: 10,
          name: "highlight10",
        },
        {
          id: 11,
          name: "highlight11",
        },
      ],
      ii: "",
      menudel: false,
      walletAddress: "",
      connected: false,
      biaozhu: false,
      hoverAddress: false,
      meteorimg: "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/meteor8.png",
      highlight_irl: false,
      highlight_art: false,
      highlight_music: false,
      highlight_verse: false,
      highlight_machine: false,
      highlight_fast: false,
      highlight_you: false,
      highlight_car: false,
      loadplay: true,
      load: true,
      stop: false,
      musicSrc: "",
      index: 0,
      singerNames: "",
      singers: "",
      startAction: false,
      musicArr: [
        {
          singer: "新裤子",
          singerName: "你要跳舞么",
          url: "https://master.ohdat.io/peking_monsters/website_music/%E6%96%B0%E8%A3%A4%E5%AD%90%20-%20%E4%BD%A0%E8%A6%81%E8%B7%B3%E8%88%9E%E5%90%97.mp3",
        },
        {
          singer: "新裤子",
          singerName: "生命因你而火热",
          url: "https://master.ohdat.io/peking_monsters/website_music/%E6%96%B0%E8%A3%A4%E5%AD%90%20-%20%E7%94%9F%E5%91%BD%E5%9B%A0%E4%BD%A0%E8%80%8C%E7%81%AB%E7%83%AD.mp3",
        },
        {
          singer: "新裤子",
          singerName: "没有理想的人不伤心",
          url: "https://master.ohdat.io/peking_monsters/website_music/%E6%96%B0%E8%A3%A4%E5%AD%90%20-%20%E6%B2%A1%E6%9C%89%E7%90%86%E6%83%B3%E7%9A%84%E4%BA%BA%E4%B8%8D%E4%BC%A4%E5%BF%83.mp3",
        },
        {
          singer: "新裤子",
          singerName: "别再问我什么是迪斯科",
          url: "https://master.ohdat.io/peking_monsters/website_music/%E6%96%B0%E8%A3%A4%E5%AD%90%20-%20%E5%88%AB%E5%86%8D%E9%97%AE%E6%88%91%E4%BB%80%E4%B9%88%E6%98%AF%E8%BF%AA%E6%96%AF%E7%A7%91.mp3",
        },
        {
          singer: "新裤子",
          singerName: "野人也有爱",
          url: "https://master.ohdat.io/peking_monsters/website_music/%E6%96%B0%E8%A3%A4%E5%AD%90%20-%20%E9%87%8E%E4%BA%BA%E4%B9%9F%E6%9C%89%E7%88%B1.mp3",
        },
      ],
      // musicArr: [
      //   {
      //     singerName: "The Stone Roses",
      //     singer: "Waterfall",
      //     url: "https://master.ohdat.io/peking_monsters/website_music/[MP3DL.CC] The Stone Roses - Waterfall (Official Video)-320k.MP3",
      //   },
      //   {
      //     singerName: "Porter Robinson",
      //     singer: "Goodbye To A World",
      //     url: "https://master.ohdat.io/peking_monsters/website_music/[MP3DL.CC] Porter Robinson - Goodbye To A World (Official Audio)-320k.MP3",
      //   },
      //   {
      //     singerName: "Nicolas Jaar",
      //     singer: "Space Is Only Noise If You Can See",
      //     url: "https://master.ohdat.io/peking_monsters/website_music/[MP3DL.CC] Nicolas Jaar - Space Is Only Noise If You Can See-320k.MP3",
      //   },
      //   {
      //     singerName: "Crystal Castles",
      //     singer: "Not In Love ft. Robert Smith of The Cure",
      //     url: "https://master.ohdat.io/peking_monsters/website_music/[MP3DL.CC] Crystal Castles - Not In Love ft. Robert Smith of The Cure-320k.MP3",
      //   },
      //   {
      //     singerName: "Of Montreal",
      //     singer: "Nonpareil Of Favor",
      //     url: "https://master.ohdat.io/peking_monsters/website_music/[MP3DL.CC] Of Montreal - _Nonpareil Of Favor_ _ NPR MUSIC LIVE-320k.MP3",
      //   },
      //   {
      //     singerName: "This Will Destroy You",
      //     singer: "Quiet",
      //     url: "https://master.ohdat.io/peking_monsters/website_music/[MP3DL.CC] This Will Destroy You - Quiet (CD Version)-320k.MP3",
      //   },
      //   {
      //     singerName: "Loaded",
      //     singer: "Primal Scream",
      //     url: "https://master.ohdat.io/peking_monsters/website_music/[MP3DL.CC] Loaded - Primal Scream-320k.MP3",
      //   },
      //   {
      //     singerName: "Black Rebel Motorcycle Club",
      //     singer: "Beat the Devil's Tattoo",
      //     url: "https://master.ohdat.io/peking_monsters/website_music/[MP3DL.CC] Black Rebel Motorcycle Club - Beat the Devil's Tattoo-320k.MP3",
      //   },
      //   {
      //     singerName: "Kraftwerk",
      //     singer: "Neon Lights",
      //     url: "https://master.ohdat.io/peking_monsters/website_music/[MP3DL.CC] Kraftwerk - Neon Lights-320k.MP3",
      //   },
      //   {
      //     singerName: "Chemical Brothers",
      //     singer: "Under the Influence",
      //     url: "https://master.ohdat.io/peking_monsters/website_music/[MP3DL.CC] Chemical Brothers - Under the Influence-320k.MP3",
      //   },
      //   {
      //     singerName: "The Cure",
      //     singer: "Friday I'm In Love",
      //     url: "https://master.ohdat.io/peking_monsters/website_music/[MP3DL.CC] The Cure - Friday I'm In Love (Official Video)-320k.MP3",
      //   },
      //   {
      //     singerName: "Unknown Mortal Orchestra",
      //     singer: "So Good At Being In Trouble",
      //     url: "https://master.ohdat.io/peking_monsters/website_music/[MP3DL.CC] Unknown Mortal Orchestra - So Good At Being In Trouble (Official Video)-320k.MP3",
      //   },
      //   {
      //     singerName: "Starship",
      //     singer: "Spacemen",
      //     url: "https://master.ohdat.io/peking_monsters/website_music/[MP3DL.CC] Starship - Spacemen 3-320k.MP3",
      //   },
      //   {
      //     singerName: "MGMT",
      //     singer: "Kids",
      //     url: "https://master.ohdat.io/peking_monsters/website_music/[MP3DL.CC] MGMT - Kids (Official HD Video)-320k.MP3",
      //   },
      //   {
      //     singerName: "Elliott Smith",
      //     singer: "Between The Bars",
      //     url: "https://master.ohdat.io/peking_monsters/website_music/[MP3DL.CC] Elliott Smith - Between The Bars-320k.MP3",
      //   },
      //   {
      //     singerName: "Kraftwerk",
      //     singer: "The Man Machine",
      //     url: "https://master.ohdat.io/peking_monsters/website_music/[MP3DL.CC] Kraftwerk - The Man Machine (live) [HD]-320k.MP3",
      //   },
      //   {
      //     singerName: "The Flaming Lips",
      //     singer: "The Flaming Lips Yoshimi Battles the Pink Robots Part",
      //     url: "https://master.ohdat.io/peking_monsters/website_music/[MP3DL.CC] the flaming lips yoshimi battles the pink robots part 1-320k.MP3",
      //   },
      //   {
      //     singerName: "Spiritualized",
      //     singer: "I Think Im in Love (The Chemical Brothers Remix)",
      //     url: "https://master.ohdat.io/peking_monsters/website_music/[MP3DL.CC] Spiritualized -  I Think Im in Love (The Chemical Brothers Remix)-320k.MP3",
      //   },
      //   {
      //     singerName: "The Jesus And Mary Chain",
      //     singer: "Just Like Honey",
      //     url: "https://master.ohdat.io/peking_monsters/website_music/[MP3DL.CC] The Jesus And Mary Chain - Just Like Honey (Official Music Video)-320k.MP3",
      //   },
      //   {
      //     singerName: "Mazzy Star",
      //     singer: "Look On Down From The Bridge",
      //     url: "https://master.ohdat.io/peking_monsters/website_music/[MP3DL.CC] Mazzy Star - Look On Down From The Bridge-320k.MP3",
      //   },
      //   {
      //     singerName: "Pulp",
      //     singer: "Common People",
      //     url: "https://master.ohdat.io/peking_monsters/website_music/[MP3DL.CC] Pulp - Common People (Official Video)-320k.MP3",
      //   },
      //   {
      //     singerName: "Moses Gunn Collective",
      //     singer: "Hole In The Wall",
      //     url: "https://master.ohdat.io/peking_monsters/website_music/[MP3DL.CC] Moses Gunn Collective - Hole In The Wall (Official Video)-320k.MP3",
      //   },
      //   {
      //     singerName: "Neil Young",
      //     singer: "Heart Of Gold",
      //     url: "https://master.ohdat.io/peking_monsters/website_music/[MP3DL.CC] Neil Young - Heart Of Gold-320k.MP3",
      //   },
      //   {
      //     singerName: "Sleigh Bells",
      //     singer: "Rill Rill",
      //     url: "https://master.ohdat.io/peking_monsters/website_music/[MP3DL.CC] Sleigh Bells - Rill Rill-320k.MP3",
      //   },
      //   {
      //     singerName: "There's A Light",
      //     singer: "We Choose To Go To The Moon",
      //     url: "https://master.ohdat.io/peking_monsters/website_music/[MP3DL.CC] There's A Light - We Choose To Go To The Moon-320k.MP3",
      //   },
      //   {
      //     singerName: "Kraftwerk",
      //     singer: "The Robots",
      //     url: "https://master.ohdat.io/peking_monsters/website_music/[MP3DL.CC] Kraftwerk - The Robots (live) [HD]-320k.MP3",
      //   },
      //   {
      //     singerName: "Synthwave_ Stilz",
      //     singer: "Starcrash (Feat. Takahashi Jones)",
      //     url: "https://master.ohdat.io/peking_monsters/website_music/[MP3DL.CC] Synthwave_ Stilz - Starcrash (Feat. Takahashi Jones) (2017) [Lazerdiscs Records]-320k.MP3",
      //   },
      //   {
      //     singerName: "Wild Horses",
      //     singer: "Rolling Stones",
      //     url: "https://master.ohdat.io/peking_monsters/website_music/[MP3DL.CC] Wild Horses - Rolling Stones-320k.MP3",
      //   },
      //   {
      //     singerName: "Tunturia",
      //     singer: "October 4, 1957 (Prologue) by Tunturia",
      //     url: "https://master.ohdat.io/peking_monsters/website_music/[MP3DL.CC] October 4, 1957 (Prologue) by Tunturia-320k.MP3",
      //   },
      // ],
      mintbox: false,
      tannum: "",
      original_stock: "",
      price: "",
      status: "0",
      stock: 0,
      limit: "0",
      limitbig: "",
      available: 1,
      ethPrice: "",
      totalPrice: "",
      haveToken: false,
      Notclickable: true,
      Afterclicking: false,
      viewonop: false,
      checkbox: false,
      checkchaimg: "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/dui.png",
      checktitle: "You are not in the whitelist!",
      lang: "",
      pass_list: [],
      pass_list1: [],
      passimg: "",
      PassKa: false,
      pass_id: "-1",
      contract_method: "mint",
      inquire: false,
      inquireText: "",
      inquireRemaining: 0,
      pass_holder: 0,
      Remainingtrue: false,
      white_list_holder: 0,
      whitelisttr_text: "",
      passxia: false,
      CHECK_MY_STATUS: -1,
      whiteListHolder: 0,
      preloadCount: 0,
      percent: 0,
    };
  },
  watch: {
    preloadCount: function (val) {
      // console.log("loaded", val)
      if (val === 14) {
        // 图片加载完成后跳转页面
        // this.$router.push({path: 'cover'})
      }
    },
    decipherment: function (oldValue) {
      // this.revise_text= false;
      if (oldValue == true) {
        this.chin = "中文";
        this.eng = "ENGLISH";
      } else {
        this.chin = "ENGLISH";
        this.eng = "中文";
      }
      this.windowScroll();
    },
  },
  mounted() {
    this.preload();
    this.language();
    this.decipherment = this.$route.params.decipherment;
    var that = this;
    this.singerNames = this.musicArr[this.index].singerName;
    this.singers = this.musicArr[this.index].singer;
    this.musicSrc = this.musicArr[this.index].url;
    this.$refs.initAudio.addEventListener(
        "ended",
        function () {
          //结束
          that.next();
        },
        false
    );
    let headerScroll = this.$refs.headerScroll.offsetTop;
    this.roadDom = this.$refs.roadScroll.offsetTop - headerScroll;
    this.meteorDom = this.$refs.meteorScroll.offsetTop - headerScroll;
    console.log(headerScroll);
    console.log(this.roadDom);
    console.log(this.meteorDom);
    this.hole = this.$refs.hole.offsetTop;
    this.road1 = this.$refs.road1.offsetTop;
    this.road3 = this.$refs.road3.offsetTop;
    this.road5 = this.$refs.road5.offsetTop;
    this.islandsS = this.$refs.islandsScroll.offsetTop;
    this.panduan();
    // this.watchWeb3Event();
    // this.checkLogIn();
    // this.getGoodsDatail();
  },
  methods: {
    preload() {
      let imgs = [
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/mebudel.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/road_kuangM.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/roadmap.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/road_kuang.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/playland.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/xia.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/openseaM.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/twitterM.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/menu.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/down.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/road.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/roadM.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/duck.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/duckright.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/road6.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/road5.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/jia.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/road3.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/road2.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/road4.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/road1.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/black_hole.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/villain_red.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/villain_blue.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/xingxing.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/meteor9.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/carall1.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/title.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/islands.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/islandsM.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/highlight1.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/highlight2.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/highlight3.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/highlight4.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/highlight5.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/highlight6.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/highlight7.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/highlight8.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/highlight9.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/highlight10.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/highlight11.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/road_cha.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/biao.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/roadmap_cha.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/open.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/road_cha1.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/meteor8.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/meteor88.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/road_cha3.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/road_cha4.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/road_cha5.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/road_cha2.png",
      ]
      for (let img of imgs) {
        let image = new Image()
        image.src = img
        image.onload = () => {
          this.preloadCount++
          // 计算图片加载的百分数，绑定到percent变量
          let percentNum = Math.floor(this.preloadCount / 14 * 100)
          this.percent = `${percentNum}%`
        }
      }
    },
    passxiala() {
      this.passxia = !this.passxia;
    },
    pass_list_tab(e) {
      console.log(e);
      this.passimg = e.id;
      this.limit = e.purchaseLimit;
      this.tannum = e.purchaseLimit;
      this.passxia = false;
    },
    party() {
      this.$router.push({
        name: "party",
        params: {decipherment: this.decipherment},
      });
    },
    world() {
      this.$router.push({
        name: "worldCup",
        params: {decipherment: this.decipherment},
      });
    },
    stake() {
      this.$router.push({
        name: "stake",
        params: {decipherment: this.decipherment},
      });
    },
    changeCloth(){
      this.$router.push({
        name: "worldCupCloth",
        params: {decipherment: this.decipherment},
      });
    },
    starry(){
      this.$router.push({
        name: "3DPARTY",
        params: {decipherment: this.decipherment},
      });
    },
    panduan() {
      let Bra = document.getElementById("mint");
      let xing = document.getElementById("miao3");
      let xing1 = document.getElementById("miao2");
      let mM = document.getElementById("headerMM");
      let mPC = document.getElementById("headerPc");
      if (window.screen.width < 1024) {
        window.addEventListener("scroll", this.windowScrollM, true);
        this.highM();
        // var u = navigator.userAgent;
        // var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
        // var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android终端
        // if (isiOS) {
        //   Bra.style.background = "#E7AB52";
        //   xing.style.background = "#E7AB52";
        //   xing1.style.background = "#E7AB52";
        //   mM.style.background = "rgba(231, 171, 82, .6)";
        // } else if (isAndroid) {
        //   Bra.style.background = "#E7AB52";
        //   xing.style.background = "#E7AB52";
        //   xing1.style.background = "#E7AB52";
        //   mM.style.background = "rgba(231, 171, 82, .6)";
        // }
      } else {
        window.addEventListener("scroll", this.windowScroll, true);
        var agent = navigator.userAgent.toLowerCase();
        var isMac = /macintosh|mac os x/i.test(navigator.userAgent);
        if (agent.indexOf("win32") >= 0 || agent.indexOf("wow32") >= 0) {
          // console.log("这是windows32位系统");
          Bra.style.background = "#E7AB52";
          xing.style.background = "#E7AB52";
          xing1.style.background = "#E7AB52";
          mPC.style.background = "rgba(231, 171, 82, .6)";
          this.meteorimg = "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/meteor88.png";
        }
        if (agent.indexOf("win64") >= 0 || agent.indexOf("wow64") >= 0) {
          // console.log("这是windows64位系统");
          Bra.style.background = "#E7AB52";
          xing.style.background = "#E7AB52";
          xing1.style.background = "#E7AB52";
          mPC.style.background = "rgba(231, 171, 82, .6)";
          this.meteorimg = "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/meteor88.png";
        }
        if (isMac) {
          // console.log("这是mac系统");
          Bra.style.background = "#EAB45E";
          xing.style.background = "#EAB45E";
          xing1.style.background = "#EAB45E";
          mPC.style.background = "rgba(234, 180, 94, .6)";
          this.meteorimg = "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/meteor8.png";
        }
      }
    },
    language() {
      var language = null;
      if (navigator.appName == "Netscape") {
        language = navigator.language;
      } else {
        language = navigator.browserLanguage;
      }
      if (language.indexOf("en") > -1) {
        this.lang = "en";
        this.decipherment = true;
        // alert("yingwen");
      } else if (language.indexOf("zh") > -1) {
        this.lang = "zh";
        this.decipherment = false;
        // alert("zhongwen");
      } else {
        this.lang = "en";
        this.decipherment = true;
        // alert("yingwen");
      }
      // console.log(this.lang);
    },
    pass_status() {
      this.inquireText = "";
      this.inquireRemaining = 0;
      this.inquire = true;
      this.Remainingtrue = false;
    },
    inquirechange() {
      this.Remainingtrue = false;
    },
    async pass_status_submit() {
      var number = new RegExp("^[0-9]*[0-9][0-9]*$");
      if (number.test(this.inquireText)) {
        if (this.inquireText <= 153) {
          const params = {
            goods_id: 8,
            token_id: this.inquireText,
          };
          let res = await check_pass_status(params);
          if (res.code === 200) {
            this.inquireRemaining = res.data.remain;
            this.Remainingtrue = true;
          }
        } else {
          if (this.decipherment) {
            Message({
              message: "Invalid Pass ID",
              duration: 3 * 1000,
              type: "error",
            });
          } else {
            Message({
              message: "请输入正确的Pass ID",
              duration: 3 * 1000,
              type: "error",
            });
          }
        }
      } else {
        if (this.decipherment) {
          Message({
            message: "Invalid Pass ID",
            duration: 3 * 1000,
            type: "error",
          });
        } else {
          Message({
            message: "请输入正确的Pass ID",
            duration: 3 * 1000,
            type: "error",
          });
        }
      }
    },
    async whitelisttr() {
      const params = {
        goods_id: 8,
      };
      let res = await check_wallet_status(params);
      console.log("白名单", res);
      if (res.code === 200) {
        this.whiteListHolder = res.data.white_list_holder;
        // alert(this.status)
        if (this.status == 1) {
          this.CHECK_MY_STATUS = -1;
        } else if (this.status == -1) {
          this.CHECK_MY_STATUS = -1;
        } else {
          if (res.data.pass_holder == 1) {
            if (res.data.white_list_holder == 0) {
              this.CHECK_MY_STATUS = 2;
            } else {
              this.CHECK_MY_STATUS = 3;
            }
          } else {
            if (res.data.white_list_holder == 0) {
              this.CHECK_MY_STATUS = 0;
            } else {
              this.CHECK_MY_STATUS = 1;
            }
          }
        }

        // this.pass_holder = res.data.pass_holder;
        // this.white_list_holder = res.data.white_list_holder;
        // this.checktitle = res.data.remark;
        // this.pass_list1 = res.data.pass_list;
        // this.checkbox = true;
      }
    },
    async ethbox() {
      this.pass_id = -1;
      // await this.getGoodsDatail();
      // await this.getPurchaseOptions();
      this.mintbox = true;
    },
    tanjian() {
      if (this.tannum != 0) {
        if (this.tannum <= 1) {
          this.tannum = 1;
        } else {
          this.tannum--;
        }
        this.totalPrice = (this.ethPrice * this.tannum).toFixed(1);
      }
    },
    tanjia() {
      if (this.tannum != 0) {
        if (this.tannum >= this.limit) {
          this.tannum = this.limit;
        } else {
          this.tannum++;
        }
        this.totalPrice = (this.ethPrice * this.tannum).toFixed(1);
      }
    },
    // test() {
    //   var node = document.getElementById("road_left_text");
    //   domtoimage
    //     .toPng(node)
    //     .then(function (dataUrl) {
    //       var img = new Image();
    //       img.src = dataUrl;
    //       console.log(img);
    //       // document.body.appendChild(img);
    //     })
    //     .catch(function (error) {
    //       console.error("oops, something went wrong!", error);
    //     });
    // },
    windowScroll() {
      let html = document.documentElement;
      let wW = html.clientWidth;
      let DuckImg = document.getElementById("duckimg");
      let RoadLeftText = document.getElementById("road_left_text");
      let VillainRed = document.getElementById("villain_red");
      let VillainBlue = document.getElementById("villain_blue");
      let CarAll = document.getElementById("car_all");
      let widd = "";
      let heigg = "";
      let caralltopnum = "";
      let caralltopnum1 = "";
      let caralltopnum2 = "";
      let caralltopnum3 = "";
      let caralltopnum4 = "";
      let caralltopnum5 = "";
      let caralltopscc = "";
      let caralltopstoo = "";
      let caralltopjia = "";
      let caralltopjian = "";
      let imgtop = "";
      let texttop = "";
      let RoadTexttop = "";
      let RoadTextbottom = "";
      let RoadTexttop1 = "";
      let RoadTextbottom1 = "";
      let RoadTexttop2 = "";
      let RoadTextbottom2 = "";
      if (wW < 1800) {
        widd = 800;
        heigg = 800;
        caralltopnum = 570;
        caralltopnum1 = 1070;
        caralltopnum2 = 631;
        caralltopnum3 = 1305;
        caralltopnum4 = 265;
        caralltopnum5 = 4273;
        caralltopscc = 0.0018;
        caralltopstoo = 0.9;
        caralltopjia = 1.4;
        caralltopjian = 1.5;
        imgtop = -400;
        texttop = 850;
        RoadTexttop = "12%";
        RoadTextbottom = "";
        RoadTexttop1 = "30%";
        RoadTextbottom1 = "";
        RoadTexttop2 = "";
        RoadTextbottom2 = "10%";
      } else if (wW >= 1800 && wW < 2500) {
        widd = 690;
        heigg = 1000;
        caralltopnum = 737;
        caralltopnum1 = 1257;
        caralltopnum2 = 851;
        caralltopnum3 = 1571;
        caralltopnum4 = 333;
        caralltopnum5 = 4639;
        caralltopscc = 0.00091;
        caralltopstoo = 0.9;
        caralltopjia = 1.3;
        caralltopjian = 1.5;
        imgtop = -400;
        texttop = 850;
        RoadTexttop = "12%";
        RoadTextbottom = "";
        RoadTexttop1 = "30%";
        RoadTextbottom1 = "";
        RoadTexttop2 = "";
        RoadTextbottom2 = "10%";
      } else if (wW >= 2500 && wW <= 3000) {
        widd = 690;
        heigg = 1400;
        caralltopnum = 737;
        caralltopnum1 = 1515;
        caralltopnum2 = 1209;
        caralltopnum3 = 2046;
        caralltopnum4 = 438;
        caralltopnum5 = 5200;
        caralltopscc = 0.0008;
        caralltopstoo = 0.92;
        caralltopjia = 1.3;
        caralltopjian = 1.5;
        imgtop = -400;
        texttop = 1300;
        RoadTexttop = "16.8%";
        RoadTextbottom = "";
        RoadTexttop1 = "30%";
        RoadTextbottom1 = "";
        RoadTexttop2 = "";
        RoadTextbottom2 = "17%";
      } else {
        widd = 690;
        heigg = 2200;
        caralltopnum = 737;
        caralltopnum1 = 1745;
        caralltopnum2 = 1645;
        caralltopnum3 = 2456;
        caralltopnum4 = 681;
        caralltopnum5 = 6365;
        caralltopscc = 0.0005;
        caralltopstoo = 1;
        caralltopjia = 1.5;
        caralltopjian = 1.5;
        imgtop = -400;
        texttop = 1400;
        RoadTexttop = "26%";
        RoadTextbottom = "";
        RoadTexttop1 = "30%";
        RoadTextbottom1 = "";
        RoadTexttop2 = "";
        RoadTextbottom2 = "10%";
      }
      // 卷去高度
      let scrollTop =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop;
      var scroll = scrollTop - this.i;
      this.i = scrollTop;

      // console.log("卷去高度", this.i);
      // console.log("上滑高度差",scroll);
      // console.log("盒子高度", this.meteorDom);
      // console.log("盒子高度", wW);
      if (this.i < this.roadDom) {
        if (this.decipherment) {
          this.title_text = "Peking Monsters";
          this.title_text1 = "Express";
          this.title_text2 =
              "Testing, 123, this is your trip guide speaking. We are a party of 2777 monsters heading to the Rock³verse in the Ethereum blockchain. The world may be in lockdown, but the show DOES NOT stop, so unbuckle those safety belts, chug that Remaining alcohol, and put on your predator sunglasses. It's gonna be a rough rough ride.";
        } else {
          this.title_text = "列车";
          this.title_text1 = "北海怪兽号";
          this.title_text2 =
              "喂？喂，123，我是你们本趟旅程的向导。 我们是2777个怪兽，前方目的地：以太坊区块链中的摇滚宇宙站。无论你身处的世界是否还处于严肃的封锁状态，在这里，表演与狂欢将永不停歇。解开安全带吧，把剩余的酒精速速清场，戴上你们专属的顶猎太阳镜。 充满挑战的旅途即将启程！";
        }
        this.DuckImgTop = 0;
        this.DuckImgLeft = 0;
        this.DuckImgscale = 1;
        this.VillainRedscale = 1;
        this.VillainRedrotate = 0;
        this.VillainRedLeft = 0;
        this.VillainRedTop = 0;
        this.VillainBluescale = 1;
        this.VillainBluerotate = 0;
        this.VillainBlueLeft = 0;
        this.VillainBlueTop = 0;
        DuckImg.style.transform =
            "translate(" +
            this.DuckImgLeft +
            "px" +
            "," +
            this.DuckImgTop +
            "px)" +
            " " +
            "scale(" +
            this.DuckImgscale +
            ")";
        VillainRed.style.transform =
            "translate(" +
            this.VillainRedLeft +
            "px" +
            "," +
            this.VillainRedTop +
            "px)" +
            " " +
            "rotate(" +
            this.VillainRedrotate +
            "deg)" +
            " " +
            "scale(" +
            this.VillainRedscale +
            ")";

        VillainBlue.style.transform =
            "translate(" +
            this.VillainBlueLeft +
            "px" +
            "," +
            this.VillainBlueTop +
            "px)" +
            " " +
            "rotate(" +
            this.VillainBluerotate +
            "deg)" +
            " " +
            "scale(" +
            this.VillainBluescale +
            ")";
        // console.log("yangshishishish", DuckImg.style);
      } else if (this.i >= this.roadDom && this.i < this.meteorDom - 650) {
        // if (this.i > this.meteorDom - 1200) {
        //   RoadLeftText.style.position = "absolute";
        //   RoadLeftText.style.bottom = "450px";
        //   RoadLeftText.style.top = "";
        // } else {
        RoadLeftText.style.position = "fixed";
        RoadLeftText.style.top = "30%";
        RoadLeftText.style.bottom = "0px";
        // }

        if (this.i <= this.roadDom + this.road1) {
          if (this.decipherment) {
            this.title_text = "Peking Monsters";
            this.title_text1 = "Express";
            this.title_text2 =
                "Testing, 123, this is your trip guide speaking. We are a party of 2777 monsters heading to the Rock³verse in the Ethereum blockchain. The world may be in lockdown, but the show DOES NOT stop, so unbuckle those safety belts, chug that Remaining alcohol, and put on your predator sunglasses. It's gonna be a rough rough ride.";
          } else {
            this.title_text = "列车";
            this.title_text1 = "北海怪兽号";
            this.title_text2 =
                "喂？喂，123，我是你们本趟旅程的向导。 我们是2777个怪兽，前方目的地：以太坊区块链中的摇滚宇宙站。无论你身处的世界是否还处于严肃的封锁状态，在这里，表演与狂欢将永不停歇。解开安全带吧，把剩余的酒精速速清场，戴上你们专属的顶猎太阳镜。 充满挑战的旅途即将启程！";
          }
        } else if (
            this.i > this.roadDom + this.road1 + 200 &&
            this.i <= this.roadDom + this.road3 + 200
        ) {
          if (this.decipherment) {
            this.title_text = "Trip Guide:";
            this.title_text1 = "Peng Lei";
            this.title_text2 =
                "Peng Lei, cartoonist, director, vocalist and guitarist of New Pants, author of graphic novel Peking Monster. With a strong love for high contrast and genre intermixture, he underlines his notion of an idealistic world beneath realistic constructions. Same as his ever eccentric works, this project is a blender of whirling visual and audio sensations.";
          } else {
            this.title_text = "旅途向导：";
            this.title_text1 = "彭磊";
            this.title_text2 =
                "彭磊，漫画家、导演、歌手、吉他手，漫画小说《北海怪兽》作者。用对比强烈的色彩、粗粒的线条和戏剧化的情节，他向我们描绘了一个现实桎梏中的理想世界。 与他以往怪癖的作品一样，这是一个为你带来视觉和听觉双重冲击的艺术系列。";
          }
        } else if (this.i > this.roadDom + this.road3 + 200) {
          if (this.decipherment) {
            this.title_text = "Seating Plan";
            this.title_text1 = "";
            this.title_text2 =
                "2777 total supply, 255 withhold by team. 777 Backstage Lists with 308 for OHDAT Genesis Pass holders, 1745 for public sale. 777+X signed editions in total.";
          } else {
            this.title_text = "列车座位表";
            this.title_text1 = "";
            this.title_text2 =
                "2777的总供应量，其中的255个由团队保留。在2777个怪兽中，有777 + X个将会是带有艺术家彭磊独家签名的限量版，其中308个会留给我们尊贵的OHDAT Genesis Pass牛逼持有者，469个我们会在后台门口挨个发放，其余的供应量将会分配到公售阶段。自个儿算，忘掉教你数学的那位体育老师吧。";
          }
        }
        if (scroll < 0) {
          var evennamber = Math.trunc(this.i / 75);
          if (evennamber % 2 == 0) {
            this.direction = true;
          } else {
            this.direction = false;
          }
          if (this.DuckImgTop >= 0) {
            this.DuckImgTop = this.DuckImgTop - Math.abs(scroll) * 1;
          } else {
            this.DuckImgTop = 0;
          }
          if (this.DuckImgTop < 36) {
            this.VillainRedscale = 1;
            this.VillainRedrotate = 0;
            this.VillainRedLeft = 0;
            this.VillainRedTop = 0;
            this.VillainBluescale = 1;
            this.VillainBluerotate = 0;
            this.VillainBlueLeft = 0;
            this.VillainBlueTop = 0;
            VillainRed.style.transform =
                "translate(" +
                this.VillainRedLeft +
                "px" +
                "," +
                this.VillainRedTop +
                "px)" +
                " " +
                "rotate(" +
                this.VillainRedrotate +
                "deg)" +
                " " +
                "scale(" +
                this.VillainRedscale +
                ")";

            VillainBlue.style.transform =
                "translate(" +
                this.VillainBlueLeft +
                "px" +
                "," +
                this.VillainBlueTop +
                "px)" +
                " " +
                "rotate(" +
                this.VillainBluerotate +
                "deg)" +
                " " +
                "scale(" +
                this.VillainBluescale +
                ")";
            this.DuckImgLeft = 0;
            DuckImg.style.transform =
                "translate(" +
                this.DuckImgLeft +
                "px" +
                "," +
                this.DuckImgTop +
                "px)" +
                " " +
                "scale(" +
                this.DuckImgscale +
                ")";
          } else if (this.DuckImgTop > 36 && this.DuckImgTop < 200) {
            this.VillainRedscale = 1;
            this.VillainRedrotate = 0;
            this.VillainRedLeft = 0;
            this.VillainRedTop = 0;
            this.VillainBluescale = 1;
            this.VillainBluerotate = 0;
            this.VillainBlueLeft = 0;
            this.VillainBlueTop = 0;
            VillainRed.style.transform =
                "translate(" +
                this.VillainRedLeft +
                "px" +
                "," +
                this.VillainRedTop +
                "px)" +
                " " +
                "rotate(" +
                this.VillainRedrotate +
                "deg)" +
                " " +
                "scale(" +
                this.VillainRedscale +
                ")";

            VillainBlue.style.transform =
                "translate(" +
                this.VillainBlueLeft +
                "px" +
                "," +
                this.VillainBlueTop +
                "px)" +
                " " +
                "rotate(" +
                this.VillainBluerotate +
                "deg)" +
                " " +
                "scale(" +
                this.VillainBluescale +
                ")";
            this.DuckImgLeft = this.DuckImgLeft - Math.abs(scroll) * 0.5;
            DuckImg.style.transform =
                "translate(" +
                this.DuckImgLeft +
                "px" +
                "," +
                this.DuckImgTop +
                "px)" +
                " " +
                "scale(" +
                this.DuckImgscale +
                ")";
          } else if (this.DuckImgTop >= 200 && this.DuckImgTop < 330) {
            this.VillainRedscale = 1;
            this.VillainRedrotate = 0;
            this.VillainRedLeft = 0;
            this.VillainRedTop = 0;
            this.VillainBluescale = 1;
            this.VillainBluerotate = 0;
            this.VillainBlueLeft = 0;
            this.VillainBlueTop = 0;
            VillainRed.style.transform =
                "translate(" +
                this.VillainRedLeft +
                "px" +
                "," +
                this.VillainRedTop +
                "px)" +
                " " +
                "rotate(" +
                this.VillainRedrotate +
                "deg)" +
                " " +
                "scale(" +
                this.VillainRedscale +
                ")";

            VillainBlue.style.transform =
                "translate(" +
                this.VillainBlueLeft +
                "px" +
                "," +
                this.VillainBlueTop +
                "px)" +
                " " +
                "rotate(" +
                this.VillainBluerotate +
                "deg)" +
                " " +
                "scale(" +
                this.VillainBluescale +
                ")";
            DuckImg.style.transform =
                "translate(" +
                this.DuckImgLeft +
                "px" +
                "," +
                this.DuckImgTop +
                "px)" +
                " " +
                "scale(" +
                this.DuckImgscale +
                ")";
          } else if (this.DuckImgTop >= 330 && this.DuckImgTop < 490) {
            this.VillainRedscale = 1;
            this.VillainRedrotate = 0;
            this.VillainRedLeft = 0;
            this.VillainRedTop = 0;
            this.VillainBluescale = 1;
            this.VillainBluerotate = 0;
            this.VillainBlueLeft = 0;
            this.VillainBlueTop = 0;
            VillainRed.style.transform =
                "translate(" +
                this.VillainRedLeft +
                "px" +
                "," +
                this.VillainRedTop +
                "px)" +
                " " +
                "rotate(" +
                this.VillainRedrotate +
                "deg)" +
                " " +
                "scale(" +
                this.VillainRedscale +
                ")";

            VillainBlue.style.transform =
                "translate(" +
                this.VillainBlueLeft +
                "px" +
                "," +
                this.VillainBlueTop +
                "px)" +
                " " +
                "rotate(" +
                this.VillainBluerotate +
                "deg)" +
                " " +
                "scale(" +
                this.VillainBluescale +
                ")";
            this.DuckImgLeft = this.DuckImgLeft + Math.abs(scroll) * 0.5;
            DuckImg.style.transform =
                "translate(" +
                this.DuckImgLeft +
                "px" +
                "," +
                this.DuckImgTop +
                "px)" +
                " " +
                "scale(" +
                this.DuckImgscale +
                ")";
          } else if (this.DuckImgTop >= 490 && this.DuckImgTop < 1360) {
            this.VillainRedscale = 1;
            this.VillainRedrotate = 0;
            this.VillainRedLeft = 0;
            this.VillainRedTop = 0;
            this.VillainBluescale = 1;
            this.VillainBluerotate = 0;
            this.VillainBlueLeft = 0;
            this.VillainBlueTop = 0;
            VillainRed.style.transform =
                "translate(" +
                this.VillainRedLeft +
                "px" +
                "," +
                this.VillainRedTop +
                "px)" +
                " " +
                "rotate(" +
                this.VillainRedrotate +
                "deg)" +
                " " +
                "scale(" +
                this.VillainRedscale +
                ")";

            VillainBlue.style.transform =
                "translate(" +
                this.VillainBlueLeft +
                "px" +
                "," +
                this.VillainBlueTop +
                "px)" +
                " " +
                "rotate(" +
                this.VillainBluerotate +
                "deg)" +
                " " +
                "scale(" +
                this.VillainBluescale +
                ")";
            DuckImg.style.transform =
                "translate(" +
                this.DuckImgLeft +
                "px" +
                "," +
                this.DuckImgTop +
                "px)" +
                " " +
                "scale(" +
                this.DuckImgscale +
                ")";
          } else if (this.DuckImgTop >= 1360 && this.DuckImgTop < 1500) {
            this.VillainRedscale = 1;
            this.VillainRedrotate = 0;
            this.VillainRedLeft = 0;
            this.VillainRedTop = 0;
            this.VillainBluescale = 1;
            this.VillainBluerotate = 0;
            this.VillainBlueLeft = 0;
            this.VillainBlueTop = 0;
            VillainRed.style.transform =
                "translate(" +
                this.VillainRedLeft +
                "px" +
                "," +
                this.VillainRedTop +
                "px)" +
                " " +
                "rotate(" +
                this.VillainRedrotate +
                "deg)" +
                " " +
                "scale(" +
                this.VillainRedscale +
                ")";

            VillainBlue.style.transform =
                "translate(" +
                this.VillainBlueLeft +
                "px" +
                "," +
                this.VillainBlueTop +
                "px)" +
                " " +
                "rotate(" +
                this.VillainBluerotate +
                "deg)" +
                " " +
                "scale(" +
                this.VillainBluescale +
                ")";
            if (this.DuckImgLeft < -2.5) {
              this.DuckImgLeft = -2.5;
            } else {
              this.DuckImgLeft = this.DuckImgLeft - Math.abs(scroll) * 0.7;
            }
            DuckImg.style.transform =
                "translate(" +
                this.DuckImgLeft +
                "px" +
                "," +
                this.DuckImgTop +
                "px)" +
                " " +
                "scale(" +
                this.DuckImgscale +
                ")";
          } else if (this.DuckImgTop >= 1500) {
            if (this.DuckImgTop >= 1550) {
              RoadLeftText.style.position = "absolute";
              RoadLeftText.style.bottom = "18%";
              RoadLeftText.style.top = "";
            }
            if (this.DuckImgTop > 1700) {
              this.DuckImgscale = this.DuckImgscale + Math.abs(scroll) * 0.005;
              this.VillainRedscale =
                  this.VillainRedscale + Math.abs(scroll) * 0.005;
              this.VillainRedrotate =
                  this.VillainRedrotate - Math.abs(scroll) * 6;
              this.VillainRedLeft = this.VillainRedLeft - Math.abs(scroll) * 1;
              this.VillainRedTop = this.VillainRedTop - Math.abs(scroll) * 0.5;
              this.VillainBluescale =
                  this.VillainBluescale + Math.abs(scroll) * 0.005;
              this.VillainBluerotate =
                  this.VillainBluerotate - -(Math.abs(scroll) * 10);
              this.VillainBlueLeft =
                  this.VillainBlueLeft + Math.abs(scroll) * 1;
              this.VillainBlueTop =
                  this.VillainBlueTop - Math.abs(scroll) * 0.5;
              if (this.DuckImgscale >= 1) {
                this.DuckImgscale = 1;
              } else {
                this.DuckImgscale = this.DuckImgscale;
              }
              if (this.VillainRedscale >= 1) {
                this.VillainRedscale = 1;
              } else {
                this.VillainRedscale = this.VillainRedscale;
              }
              if (this.VillainRedLeft <= 0) {
                this.VillainRedLeft = 0;
              } else {
                this.VillainRedLeft = this.VillainRedLeft;
              }
              if (this.VillainRedTop <= 0) {
                this.VillainRedTop = 0;
              } else {
                this.VillainRedTop = this.VillainRedTop;
              }

              if (this.VillainBluescale >= 1) {
                this.VillainBluescale = 1;
              } else {
                this.VillainBluescale = this.VillainBluescale;
              }
              if (this.VillainBlueLeft >= 0) {
                this.VillainBlueLeft = 0;
              } else {
                this.VillainBlueLeft = this.VillainBlueLeft;
              }
              if (this.VillainBlueTop <= 0) {
                this.VillainBlueTop = 0;
              } else {
                this.VillainBlueTop = this.VillainBlueTop;
              }

              DuckImg.style.transform =
                  "translate(" +
                  this.DuckImgLeft +
                  "px" +
                  "," +
                  this.DuckImgTop +
                  "px)" +
                  " " +
                  "scale(" +
                  this.DuckImgscale +
                  ")";
              VillainRed.style.transform =
                  "translate(" +
                  this.VillainRedLeft +
                  "px" +
                  "," +
                  this.VillainRedTop +
                  "px)" +
                  " " +
                  "rotate(" +
                  this.VillainRedrotate +
                  "deg)" +
                  " " +
                  "scale(" +
                  this.VillainRedscale +
                  ")";

              VillainBlue.style.transform =
                  "translate(" +
                  this.VillainBlueLeft +
                  "px" +
                  "," +
                  this.VillainBlueTop +
                  "px)" +
                  " " +
                  "rotate(" +
                  this.VillainBluerotate +
                  "deg)" +
                  " " +
                  "scale(" +
                  this.VillainBluescale +
                  ")";
            } else {
              this.DuckImgscale = 1;
              this.VillainRedscale = 1;
              this.VillainRedrotate = 0;
              this.VillainRedLeft = 0;
              this.VillainRedTop = 0;
              this.VillainBluescale = 1;
              this.VillainBluerotate = 0;
              this.VillainBlueLeft = 0;
              this.VillainBlueTop = 0;
              DuckImg.style.transform =
                  "translate(" +
                  this.DuckImgLeft +
                  "px" +
                  "," +
                  this.DuckImgTop +
                  "px)" +
                  " " +
                  "scale(" +
                  this.DuckImgscale +
                  ")";
              VillainRed.style.transform =
                  "translate(" +
                  this.VillainRedLeft +
                  "px" +
                  "," +
                  this.VillainRedTop +
                  "px)" +
                  " " +
                  "rotate(" +
                  this.VillainRedrotate +
                  "deg)" +
                  " " +
                  "scale(" +
                  this.VillainRedscale +
                  ")";
              VillainBlue.style.transform =
                  "translate(" +
                  this.VillainBlueLeft +
                  "px" +
                  "," +
                  this.VillainBlueTop +
                  "px)" +
                  " " +
                  "rotate(" +
                  this.VillainBluerotate +
                  "deg)" +
                  " " +
                  "scale(" +
                  this.VillainBluescale +
                  ")";
            }
          }
        } else {
          var evennamber = Math.trunc(this.i / 75);
          if (evennamber % 2 == 0) {
            this.direction = true;
          } else {
            this.direction = false;
          }
          if (this.DuckImgTop < 36) {
            this.DuckImgTop = this.DuckImgTop + Math.abs(scroll) * 1;
            this.DuckImgLeft = 0;
            DuckImg.style.transform =
                "translate(" +
                this.DuckImgLeft +
                "px" +
                "," +
                this.DuckImgTop +
                "px)" +
                " " +
                "scale(" +
                this.DuckImgscale +
                ")";
            this.VillainRedscale = 1;
            this.VillainRedrotate = 0;
            this.VillainRedLeft = 0;
            this.VillainRedTop = 0;
            this.VillainBluescale = 1;
            this.VillainBluerotate = 0;
            this.VillainBlueLeft = 0;
            this.VillainBlueTop = 0;
            VillainRed.style.transform =
                "translate(" +
                this.VillainRedLeft +
                "px" +
                "," +
                this.VillainRedTop +
                "px)" +
                " " +
                "rotate(" +
                this.VillainRedrotate +
                "deg)" +
                " " +
                "scale(" +
                this.VillainRedscale +
                ")";

            VillainBlue.style.transform =
                "translate(" +
                this.VillainBlueLeft +
                "px" +
                "," +
                this.VillainBlueTop +
                "px)" +
                " " +
                "rotate(" +
                this.VillainBluerotate +
                "deg)" +
                " " +
                "scale(" +
                this.VillainBluescale +
                ")";
          } else if (this.DuckImgTop >= 36 && this.DuckImgTop < 200) {
            this.VillainRedscale = 1;
            this.VillainRedrotate = 0;
            this.VillainRedLeft = 0;
            this.VillainRedTop = 0;
            this.VillainBluescale = 1;
            this.VillainBluerotate = 0;
            this.VillainBlueLeft = 0;
            this.VillainBlueTop = 0;
            VillainRed.style.transform =
                "translate(" +
                this.VillainRedLeft +
                "px" +
                "," +
                this.VillainRedTop +
                "px)" +
                " " +
                "rotate(" +
                this.VillainRedrotate +
                "deg)" +
                " " +
                "scale(" +
                this.VillainRedscale +
                ")";

            VillainBlue.style.transform =
                "translate(" +
                this.VillainBlueLeft +
                "px" +
                "," +
                this.VillainBlueTop +
                "px)" +
                " " +
                "rotate(" +
                this.VillainBluerotate +
                "deg)" +
                " " +
                "scale(" +
                this.VillainBluescale +
                ")";
            this.DuckImgTop = this.DuckImgTop + Math.abs(scroll) * 1;
            this.DuckImgLeft = this.DuckImgLeft + Math.abs(scroll) * 0.5;
            DuckImg.style.transform =
                "translate(" +
                this.DuckImgLeft +
                "px" +
                "," +
                this.DuckImgTop +
                "px)" +
                " " +
                "scale(" +
                this.DuckImgscale +
                ")";
          } else if (this.DuckImgTop >= 200 && this.DuckImgTop < 330) {
            this.VillainRedscale = 1;
            this.VillainRedrotate = 0;
            this.VillainRedLeft = 0;
            this.VillainRedTop = 0;
            this.VillainBluescale = 1;
            this.VillainBluerotate = 0;
            this.VillainBlueLeft = 0;
            this.VillainBlueTop = 0;
            VillainRed.style.transform =
                "translate(" +
                this.VillainRedLeft +
                "px" +
                "," +
                this.VillainRedTop +
                "px)" +
                " " +
                "rotate(" +
                this.VillainRedrotate +
                "deg)" +
                " " +
                "scale(" +
                this.VillainRedscale +
                ")";

            VillainBlue.style.transform =
                "translate(" +
                this.VillainBlueLeft +
                "px" +
                "," +
                this.VillainBlueTop +
                "px)" +
                " " +
                "rotate(" +
                this.VillainBluerotate +
                "deg)" +
                " " +
                "scale(" +
                this.VillainBluescale +
                ")";
            this.DuckImgTop = this.DuckImgTop + Math.abs(scroll) * 1;
            DuckImg.style.transform =
                "translate(" +
                this.DuckImgLeft +
                "px" +
                "," +
                this.DuckImgTop +
                "px)" +
                " " +
                "scale(" +
                this.DuckImgscale +
                ")";
          } else if (this.DuckImgTop >= 330 && this.DuckImgTop < 490) {
            this.VillainRedscale = 1;
            this.VillainRedrotate = 0;
            this.VillainRedLeft = 0;
            this.VillainRedTop = 0;
            this.VillainBluescale = 1;
            this.VillainBluerotate = 0;
            this.VillainBlueLeft = 0;
            this.VillainBlueTop = 0;
            VillainRed.style.transform =
                "translate(" +
                this.VillainRedLeft +
                "px" +
                "," +
                this.VillainRedTop +
                "px)" +
                " " +
                "rotate(" +
                this.VillainRedrotate +
                "deg)" +
                " " +
                "scale(" +
                this.VillainRedscale +
                ")";

            VillainBlue.style.transform =
                "translate(" +
                this.VillainBlueLeft +
                "px" +
                "," +
                this.VillainBlueTop +
                "px)" +
                " " +
                "rotate(" +
                this.VillainBluerotate +
                "deg)" +
                " " +
                "scale(" +
                this.VillainBluescale +
                ")";
            this.DuckImgTop = this.DuckImgTop + Math.abs(scroll) * 1;
            this.DuckImgLeft = this.DuckImgLeft - Math.abs(scroll) * 0.5;
            DuckImg.style.transform =
                "translate(" +
                this.DuckImgLeft +
                "px" +
                "," +
                this.DuckImgTop +
                "px)" +
                " " +
                "scale(" +
                this.DuckImgscale +
                ")";
          } else if (this.DuckImgTop >= 490 && this.DuckImgTop < 1360) {
            this.VillainRedscale = 1;
            this.VillainRedrotate = 0;
            this.VillainRedLeft = 0;
            this.VillainRedTop = 0;
            this.VillainBluescale = 1;
            this.VillainBluerotate = 0;
            this.VillainBlueLeft = 0;
            this.VillainBlueTop = 0;
            VillainRed.style.transform =
                "translate(" +
                this.VillainRedLeft +
                "px" +
                "," +
                this.VillainRedTop +
                "px)" +
                " " +
                "rotate(" +
                this.VillainRedrotate +
                "deg)" +
                " " +
                "scale(" +
                this.VillainRedscale +
                ")";

            VillainBlue.style.transform =
                "translate(" +
                this.VillainBlueLeft +
                "px" +
                "," +
                this.VillainBlueTop +
                "px)" +
                " " +
                "rotate(" +
                this.VillainBluerotate +
                "deg)" +
                " " +
                "scale(" +
                this.VillainBluescale +
                ")";
            this.DuckImgTop = this.DuckImgTop + Math.abs(scroll) * 1;
            DuckImg.style.transform =
                "translate(" +
                this.DuckImgLeft +
                "px" +
                "," +
                this.DuckImgTop +
                "px)" +
                " " +
                "scale(" +
                this.DuckImgscale +
                ")";
          } else if (this.DuckImgTop >= 1360 && this.DuckImgTop < 1500) {
            this.VillainRedscale = 1;
            this.VillainRedrotate = 0;
            this.VillainRedLeft = 0;
            this.VillainRedTop = 0;
            this.VillainBluescale = 1;
            this.VillainBluerotate = 0;
            this.VillainBlueLeft = 0;
            this.VillainBlueTop = 0;
            VillainRed.style.transform =
                "translate(" +
                this.VillainRedLeft +
                "px" +
                "," +
                this.VillainRedTop +
                "px)" +
                " " +
                "rotate(" +
                this.VillainRedrotate +
                "deg)" +
                " " +
                "scale(" +
                this.VillainRedscale +
                ")";

            VillainBlue.style.transform =
                "translate(" +
                this.VillainBlueLeft +
                "px" +
                "," +
                this.VillainBlueTop +
                "px)" +
                " " +
                "rotate(" +
                this.VillainBluerotate +
                "deg)" +
                " " +
                "scale(" +
                this.VillainBluescale +
                ")";
            this.DuckImgTop = this.DuckImgTop + Math.abs(scroll) * 1;
            if (this.DuckImgLeft > 85) {
              this.DuckImgLeft = 85;
            } else {
              this.DuckImgLeft = this.DuckImgLeft + Math.abs(scroll) * 0.7;
            }
            DuckImg.style.transform =
                "translate(" +
                this.DuckImgLeft +
                "px" +
                "," +
                this.DuckImgTop +
                "px)" +
                " " +
                "scale(" +
                this.DuckImgscale +
                ")";
          } else if (this.DuckImgTop >= 1500) {
            if (this.DuckImgTop >= 1550) {
              RoadLeftText.style.position = "absolute";
              RoadLeftText.style.bottom = "210px";
              RoadLeftText.style.top = "";
            }
            if (this.DuckImgTop > 1900) {
              this.DuckImgTop = 1900;
            } else {
              this.DuckImgTop = this.DuckImgTop + Math.abs(scroll) * 1.5;
            }
            if (this.DuckImgTop > 1700) {
              this.DuckImgscale = this.DuckImgscale - Math.abs(scroll) * 0.005;
              this.VillainRedscale =
                  this.VillainRedscale - Math.abs(scroll) * 0.005;
              this.VillainRedrotate =
                  this.VillainRedrotate + Math.abs(scroll) * 6;
              this.VillainRedLeft = this.VillainRedLeft + Math.abs(scroll) * 1;
              this.VillainRedTop = this.VillainRedTop + Math.abs(scroll) * 0.5;
              this.VillainBluescale =
                  this.VillainBluescale - Math.abs(scroll) * 0.005;
              this.VillainBluerotate =
                  this.VillainBluerotate + -(Math.abs(scroll) * 10);
              this.VillainBlueLeft =
                  this.VillainBlueLeft - Math.abs(scroll) * 1;
              this.VillainBlueTop =
                  this.VillainBlueTop + Math.abs(scroll) * 0.5;
              if (this.DuckImgscale <= 0) {
                this.DuckImgscale = 0;
              } else {
                this.DuckImgscale = this.DuckImgscale;
              }
              if (this.VillainRedscale <= 0) {
                this.VillainRedscale = 0;
              } else {
                this.VillainRedscale = this.VillainRedscale;
              }
              if (this.VillainBluescale <= 0) {
                this.VillainBluescale = 0;
              } else {
                this.VillainBluescale = this.VillainBluescale;
              }
              if (this.VillainRedLeft >= 154) {
                this.VillainRedLeft = 154;
              } else {
                this.VillainRedLeft = this.VillainRedLeft;
              }
              if (this.VillainRedTop >= 116) {
                this.VillainRedTop = 116;
              } else {
                this.VillainRedTop = this.VillainRedTop;
              }

              if (this.VillainBlueLeft <= -154) {
                this.VillainBlueLeft = -154;
              } else {
                this.VillainBlueLeft = this.VillainBlueLeft;
              }
              if (this.VillainBlueTop >= 116) {
                this.VillainBlueTop = 116;
              } else {
                this.VillainBlueTop = this.VillainBlueTop;
              }
              DuckImg.style.transform =
                  "translate(" +
                  this.DuckImgLeft +
                  "px" +
                  "," +
                  this.DuckImgTop +
                  "px)" +
                  " " +
                  "scale(" +
                  this.DuckImgscale +
                  ")";
              VillainRed.style.transform =
                  "translate(" +
                  this.VillainRedLeft +
                  "px" +
                  "," +
                  this.VillainRedTop +
                  "px)" +
                  " " +
                  "rotate(" +
                  this.VillainRedrotate +
                  "deg)" +
                  " " +
                  "scale(" +
                  this.VillainRedscale +
                  ")";
              VillainBlue.style.transform =
                  "translate(" +
                  this.VillainBlueLeft +
                  "px" +
                  "," +
                  this.VillainBlueTop +
                  "px)" +
                  " " +
                  "rotate(" +
                  this.VillainBluerotate +
                  "deg)" +
                  " " +
                  "scale(" +
                  this.VillainBluescale +
                  ")";
            } else {
              this.DuckImgscale = 1;
              this.VillainRedscale = 1;
              this.VillainRedrotate = 0;
              this.VillainRedLeft = 0;
              this.VillainRedTop = 0;
              this.VillainBluescale = 1;
              this.VillainBluerotate = 0;
              this.VillainBlueLeft = 0;
              this.VillainBlueTop = 0;
              DuckImg.style.transform =
                  "translate(" +
                  this.DuckImgLeft +
                  "px" +
                  "," +
                  this.DuckImgTop +
                  "px)" +
                  " " +
                  "scale(" +
                  this.DuckImgscale +
                  ")";
              VillainRed.style.transform =
                  "translate(" +
                  this.VillainRedLeft +
                  "px" +
                  "," +
                  this.VillainRedTop +
                  "px)" +
                  " " +
                  "rotate(" +
                  this.VillainRedrotate +
                  "deg)" +
                  " " +
                  "scale(" +
                  this.VillainRedscale +
                  ")";
              VillainBlue.style.transform =
                  "translate(" +
                  this.VillainBlueLeft +
                  "px" +
                  "," +
                  this.VillainBlueTop +
                  "px)" +
                  " " +
                  "rotate(" +
                  this.VillainBluerotate +
                  "deg)" +
                  " " +
                  "scale(" +
                  this.VillainBluescale +
                  ")";
            }
          }
        }
      } else if (this.i >= this.meteorDom - 650) {
        if (this.decipherment) {
          this.title_text = "Seating Plan";
          this.title_text1 = "";
          this.title_text2 =
              "2777 total supply, 255 withhold by team. 777 Backstage Lists with 308 for OHDAT Genesis Pass holders, 1745 for public sale. 777+X signed editions in total.";
        } else {
          this.title_text = "列车座位表";
          this.title_text1 = "";
          this.title_text2 =
              "2777的总供应量，其中的255个由团队保留。在2777个怪兽中，有777 + X个将会是带有艺术家彭磊独家签名的限量版，其中308个会留给我们尊贵的OHDAT Genesis Pass牛逼持有者，469个我们会在后台门口挨个发放，其余的供应量将会分配到公售阶段。自个儿算，忘掉教你数学的那位体育老师吧。";
        }
        this.DuckImgTop = 1900;
        this.DuckImgLeft = 85;
        this.DuckImgscale = 0;
        this.VillainRedrotate = 0;
        this.VillainRedscale = 0;
        this.VillainRedLeft = 154;
        this.VillainRedTop = 116;
        this.VillainBluerotate = 0;
        this.VillainBluescale = 0;
        this.VillainBlueLeft = -154;
        this.VillainBlueTop = 116;
        VillainRed.style.transform =
            "translate(" +
            this.VillainRedLeft +
            "px" +
            "," +
            this.VillainRedTop +
            "px)" +
            " " +
            "rotate(" +
            this.VillainRedrotate +
            "deg)" +
            " " +
            "scale(" +
            this.VillainRedscale +
            ")";
        DuckImg.style.transform =
            "translate(" +
            this.DuckImgLeft +
            "px" +
            "," +
            this.DuckImgTop +
            "px)" +
            " " +
            "scale(" +
            this.DuckImgscale +
            ")";
        VillainBlue.style.transform =
            "translate(" +
            this.VillainBlueLeft +
            "px" +
            "," +
            this.VillainBlueTop +
            "px)" +
            " " +
            "rotate(" +
            this.VillainBluerotate +
            "deg)" +
            " " +
            "scale(" +
            this.VillainBluescale +
            ")";
      }
      if (this.i < this.meteorDom - widd) {
        this.carallsca = 0.2;
        this.caralltop = imgtop;
        this.meteor_img = true;

        CarAll.style.transform =
            "translate(" +
            this.carallleft +
            "px" +
            "," +
            this.caralltop +
            "px)" +
            "scale(" +
            this.carallsca +
            ")";
        // CarAll.style.top = this.caralltop + "px";
      } else if (
          this.i >= this.meteorDom - widd &&
          this.i <= this.islandsS - heigg
      ) {
        this.carallleft = 0;
        if (scroll < 0) {
          this.carallsca = this.carallsca - Math.abs(scroll) * 0.0015;
          this.caralltop = this.caralltop - Math.abs(scroll) * 2;
          if (this.carallsca <= 0.2) {
            this.carallsca = 0.2;
            // this.caralltra = false;
            // this.carallture = true;
            this.meteor_img = true;
          } else {
            // this.caralltra = false;
            // this.carallture = true;
            this.meteor_img = true;
            this.carallsca = this.carallsca;
          }
          if (this.caralltop <= imgtop) {
            this.caralltop = imgtop;
          } else {
            this.caralltop = this.caralltop;
          }
          CarAll.style.transform =
              "translate(" +
              this.carallleft +
              "px" +
              "," +
              this.caralltop +
              "px)" +
              "scale(" +
              this.carallsca +
              ")";
          // CarAll.style.transform =
          //   "translateX(" +
          //   this.carallleft +
          //   "px)" +
          //   " " +
          //   "scale(" +
          //   this.carallsca +
          //   ")";
          // CarAll.style.top = this.caralltop + "px";
        } else {
          this.carallsca = this.carallsca + Math.abs(scroll) * 0.0015;
          this.caralltop = this.caralltop + Math.abs(scroll) * 2;
          if (this.carallsca >= 1) {
            this.carallsca = 1;
            this.meteor_img = false;
          } else {
            this.meteor_img = true;
            // this.caralltra = false;
            // this.carallture = true;
            this.carallsca = this.carallsca;
          }
          if (this.caralltop >= 660) {
            this.caralltop = 660;
          } else {
            this.caralltop = this.caralltop;
          }
          CarAll.style.transform =
              "translate(" +
              this.carallleft +
              "px" +
              "," +
              this.caralltop +
              "px)" +
              "scale(" +
              this.carallsca +
              ")";
          // CarAll.style.transform =
          //   "translateX(" +
          //   this.carallleft +
          //   "px)" +
          //   " " +
          //   "scale(" +
          //   this.carallsca +
          //   ")";
          // CarAll.style.top = this.caralltop + "px";
        }
      } else if (this.i > this.islandsS - heigg) {
        // console.log(88888);
        // this.carallsca = 1;
        // this.caralltop = 100;
        this.meteor_img = false;
        if (scroll < 0) {
          if (this.i <= caralltopnum5) {
            // if (this.caralltop <= caralltopnum) {
            this.carallsca = this.carallsca + Math.abs(scroll) * caralltopscc;
            this.caralltop = this.caralltop - Math.abs(scroll) * caralltopstoo;
            if (this.caralltop <= caralltopnum1) {
              this.carallleft =
                  this.carallleft - Math.abs(scroll) * caralltopjian;
            } else if (this.caralltop > caralltopnum1) {
              this.carallleft =
                  this.carallleft + Math.abs(scroll) * caralltopjia;
            }
            if (this.carallleft <= 0) {
              this.carallleft = 0;
            } else if (this.carallleft >= caralltopnum2) {
              this.carallleft = caralltopnum2;
            } else {
              this.carallleft = this.carallleft;
            }
            if (this.carallsca >= 1) {
              this.carallsca = 1;
            } else {
              this.carallsca = this.carallsca;
            }
            CarAll.style.transform =
                "translate(" +
                this.carallleft +
                "px" +
                "," +
                this.caralltop +
                "px)" +
                "scale(" +
                this.carallsca +
                ")";
            // CarAll.style.transform =
            //   "translateX(" +
            //   this.carallleft +
            //   "px)" +
            //   " " +
            //   "scale(" +
            //   this.carallsca +
            //   ")";
            // CarAll.style.top = this.caralltop + "px";
          }
        } else {
          this.carallsca = this.carallsca - Math.abs(scroll) * caralltopscc;
          this.caralltop = this.caralltop + Math.abs(scroll) * caralltopstoo;
          if (this.caralltop <= caralltopnum1) {
            this.carallleft = this.carallleft + Math.abs(scroll) * caralltopjia;
          } else if (this.caralltop > caralltopnum1) {
            this.carallleft =
                this.carallleft - Math.abs(scroll) * caralltopjian;
          }
          if (this.carallleft <= 0) {
            this.carallleft = 0;
          } else if (this.carallleft >= caralltopnum2) {
            this.carallleft = caralltopnum2;
          } else {
            this.carallleft = this.carallleft;
          }

          if (this.carallsca <= 0.2) {
            this.carallsca = 0.2;
            if (this.carallleft <= caralltopnum4) {
              this.carallleft = caralltopnum4;
            }
          } else {
            this.carallsca = this.carallsca;
          }
          if (this.caralltop >= caralltopnum3) {
            this.caralltop = caralltopnum3;
            this.carallleft = caralltopnum4;
          }
          CarAll.style.transform =
              "translate(" +
              this.carallleft +
              "px" +
              "," +
              this.caralltop +
              "px)" +
              "scale(" +
              this.carallsca +
              ")";
          // CarAll.style.transform =
          //   "translateX(" +
          //   this.carallleft +
          //   "px)" +
          //   " " +
          //   "scale(" +
          //   this.carallsca +
          //   ")";
          // CarAll.style.top = this.caralltop + "px";
          // console.log(CarAll.style.transform);
        }
      }
      if (this.i < this.roadDom) {
        RoadLeftText.style.position = "absolute";
        RoadLeftText.style.top = RoadTexttop;
        RoadLeftText.style.bottom = RoadTextbottom;
      } else if (this.i >= this.roadDom && this.i < this.meteorDom - texttop) {
        RoadLeftText.style.position = "fixed";
        RoadLeftText.style.top = RoadTexttop1;
        RoadLeftText.style.bottom = RoadTextbottom1;
      } else if (this.i >= this.meteorDom - texttop) {
        RoadLeftText.style.position = "absolute";
        RoadLeftText.style.top = RoadTexttop2;
        RoadLeftText.style.bottom = RoadTextbottom2;
      }
    },
    windowScrollM() {
      let DuckImg = document.getElementById("duckimg");
      let VillainRed = document.getElementById("villain_red");
      let VillainBlue = document.getElementById("villain_blue");
      let scrollTop =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop;
      var scroll = scrollTop - this.i;
      this.i = scrollTop;
      // console.log("滑动距离", this.i);
      // console.log("顶端距离", this.roadDom);
      // console.log("顶端距离", this.meteorDom);
      // console.log("盒子顶端距离",this.meteorDom-450);
      if (this.i < this.roadDom) {
        this.DuckImgTop = 0;
        this.DuckImgLeft = 0;
        this.DuckImgscale = 1;
        this.VillainRedscale = 1;
        this.VillainRedrotate = 0;
        this.VillainRedLeft = 0;
        this.VillainRedTop = 0;
        this.VillainBluescale = 1;
        this.VillainBluerotate = 0;
        this.VillainBlueLeft = 0;
        this.VillainBlueTop = 0;
        DuckImg.style.transform =
            "translate(" +
            this.DuckImgLeft +
            "px" +
            "," +
            this.DuckImgTop +
            "px)" +
            " " +
            "scale(" +
            this.DuckImgscale +
            ")";
        VillainRed.style.transform =
            "translate(" +
            this.VillainRedLeft +
            "px" +
            "," +
            this.VillainRedTop +
            "px)" +
            " " +
            "rotate(" +
            this.VillainRedrotate +
            "deg)" +
            " " +
            "scale(" +
            this.VillainRedscale +
            ")";

        VillainBlue.style.transform =
            "translate(" +
            this.VillainBlueLeft +
            "px" +
            "," +
            this.VillainBlueTop +
            "px)" +
            " " +
            "rotate(" +
            this.VillainBluerotate +
            "deg)" +
            " " +
            "scale(" +
            this.VillainBluescale +
            ")";
      } else if (this.i >= this.roadDom && this.i < this.meteorDom-450) {
        if (scroll < 0) {
          var evennamber = Math.trunc(this.i / 75);
          if (evennamber % 2 == 0) {
            this.direction = true;
          } else {
            this.direction = false;
          }
          if (this.DuckImgTop >= 0) {
            this.DuckImgTop = this.DuckImgTop - Math.abs(scroll) * 1.4;
          } else {
            this.DuckImgTop = 0;
          }
          if (this.DuckImgTop < 27) {
            this.VillainRedscale = 1;
            this.VillainRedrotate = 0;
            this.VillainRedLeft = 0;
            this.VillainRedTop = 0;
            this.VillainBluescale = 1;
            this.VillainBluerotate = 0;
            this.VillainBlueLeft = 0;
            this.VillainBlueTop = 0;
            VillainRed.style.transform =
                "translate(" +
                this.VillainRedLeft +
                "px" +
                "," +
                this.VillainRedTop +
                "px)" +
                " " +
                "rotate(" +
                this.VillainRedrotate +
                "deg)" +
                " " +
                "scale(" +
                this.VillainRedscale +
                ")";

            VillainBlue.style.transform =
                "translate(" +
                this.VillainBlueLeft +
                "px" +
                "," +
                this.VillainBlueTop +
                "px)" +
                " " +
                "rotate(" +
                this.VillainBluerotate +
                "deg)" +
                " " +
                "scale(" +
                this.VillainBluescale +
                ")";
            this.DuckImgLeft = 0;
            DuckImg.style.transform =
                "translate(" +
                this.DuckImgLeft +
                "px" +
                "," +
                this.DuckImgTop +
                "px)" +
                " " +
                "scale(" +
                this.DuckImgscale +
                ")";
          } else if (this.DuckImgTop >= 27 && this.DuckImgTop < 86) {
            this.VillainRedscale = 1;
            this.VillainRedrotate = 0;
            this.VillainRedLeft = 0;
            this.VillainRedTop = 0;
            this.VillainBluescale = 1;
            this.VillainBluerotate = 0;
            this.VillainBlueLeft = 0;
            this.VillainBlueTop = 0;
            VillainRed.style.transform =
                "translate(" +
                this.VillainRedLeft +
                "px" +
                "," +
                this.VillainRedTop +
                "px)" +
                " " +
                "rotate(" +
                this.VillainRedrotate +
                "deg)" +
                " " +
                "scale(" +
                this.VillainRedscale +
                ")";

            VillainBlue.style.transform =
                "translate(" +
                this.VillainBlueLeft +
                "px" +
                "," +
                this.VillainBlueTop +
                "px)" +
                " " +
                "rotate(" +
                this.VillainBluerotate +
                "deg)" +
                " " +
                "scale(" +
                this.VillainBluescale +
                ")";
            this.DuckImgLeft = this.DuckImgLeft - Math.abs(scroll) * 0.5;
            DuckImg.style.transform =
                "translate(" +
                this.DuckImgLeft +
                "px" +
                "," +
                this.DuckImgTop +
                "px)" +
                " " +
                "scale(" +
                this.DuckImgscale +
                ")";
          } else if (this.DuckImgTop >= 86 && this.DuckImgTop < 154) {
            this.VillainRedscale = 1;
            this.VillainRedrotate = 0;
            this.VillainRedLeft = 0;
            this.VillainRedTop = 0;
            this.VillainBluescale = 1;
            this.VillainBluerotate = 0;
            this.VillainBlueLeft = 0;
            this.VillainBlueTop = 0;
            VillainRed.style.transform =
                "translate(" +
                this.VillainRedLeft +
                "px" +
                "," +
                this.VillainRedTop +
                "px)" +
                " " +
                "rotate(" +
                this.VillainRedrotate +
                "deg)" +
                " " +
                "scale(" +
                this.VillainRedscale +
                ")";

            VillainBlue.style.transform =
                "translate(" +
                this.VillainBlueLeft +
                "px" +
                "," +
                this.VillainBlueTop +
                "px)" +
                " " +
                "rotate(" +
                this.VillainBluerotate +
                "deg)" +
                " " +
                "scale(" +
                this.VillainBluescale +
                ")";
            DuckImg.style.transform =
                "translate(" +
                this.DuckImgLeft +
                "px" +
                "," +
                this.DuckImgTop +
                "px)" +
                " " +
                "scale(" +
                this.DuckImgscale +
                ")";
          } else if (this.DuckImgTop >= 154 && this.DuckImgTop < 208) {
            this.VillainRedscale = 1;
            this.VillainRedrotate = 0;
            this.VillainRedLeft = 0;
            this.VillainRedTop = 0;
            this.VillainBluescale = 1;
            this.VillainBluerotate = 0;
            this.VillainBlueLeft = 0;
            this.VillainBlueTop = 0;
            VillainRed.style.transform =
                "translate(" +
                this.VillainRedLeft +
                "px" +
                "," +
                this.VillainRedTop +
                "px)" +
                " " +
                "rotate(" +
                this.VillainRedrotate +
                "deg)" +
                " " +
                "scale(" +
                this.VillainRedscale +
                ")";

            VillainBlue.style.transform =
                "translate(" +
                this.VillainBlueLeft +
                "px" +
                "," +
                this.VillainBlueTop +
                "px)" +
                " " +
                "rotate(" +
                this.VillainBluerotate +
                "deg)" +
                " " +
                "scale(" +
                this.VillainBluescale +
                ")";
            this.DuckImgLeft = this.DuckImgLeft + Math.abs(scroll) * 0.5;
            DuckImg.style.transform =
                "translate(" +
                this.DuckImgLeft +
                "px" +
                "," +
                this.DuckImgTop +
                "px)" +
                " " +
                "scale(" +
                this.DuckImgscale +
                ")";
          } else if (this.DuckImgTop >= 208 && this.DuckImgTop < 582) {
            this.VillainRedscale = 1;
            this.VillainRedrotate = 0;
            this.VillainRedLeft = 0;
            this.VillainRedTop = 0;
            this.VillainBluescale = 1;
            this.VillainBluerotate = 0;
            this.VillainBlueLeft = 0;
            this.VillainBlueTop = 0;
            VillainRed.style.transform =
                "translate(" +
                this.VillainRedLeft +
                "px" +
                "," +
                this.VillainRedTop +
                "px)" +
                " " +
                "rotate(" +
                this.VillainRedrotate +
                "deg)" +
                " " +
                "scale(" +
                this.VillainRedscale +
                ")";

            VillainBlue.style.transform =
                "translate(" +
                this.VillainBlueLeft +
                "px" +
                "," +
                this.VillainBlueTop +
                "px)" +
                " " +
                "rotate(" +
                this.VillainBluerotate +
                "deg)" +
                " " +
                "scale(" +
                this.VillainBluescale +
                ")";
            DuckImg.style.transform =
                "translate(" +
                this.DuckImgLeft +
                "px" +
                "," +
                this.DuckImgTop +
                "px)" +
                " " +
                "scale(" +
                this.DuckImgscale +
                ")";
          } else if (this.DuckImgTop >= 582 && this.DuckImgTop < 620) {
            this.VillainRedscale = 1;
            this.VillainRedrotate = 0;
            this.VillainRedLeft = 0;
            this.VillainRedTop = 0;
            this.VillainBluescale = 1;
            this.VillainBluerotate = 0;
            this.VillainBlueLeft = 0;
            this.VillainBlueTop = 0;
            VillainRed.style.transform =
                "translate(" +
                this.VillainRedLeft +
                "px" +
                "," +
                this.VillainRedTop +
                "px)" +
                " " +
                "rotate(" +
                this.VillainRedrotate +
                "deg)" +
                " " +
                "scale(" +
                this.VillainRedscale +
                ")";

            VillainBlue.style.transform =
                "translate(" +
                this.VillainBlueLeft +
                "px" +
                "," +
                this.VillainBlueTop +
                "px)" +
                " " +
                "rotate(" +
                this.VillainBluerotate +
                "deg)" +
                " " +
                "scale(" +
                this.VillainBluescale +
                ")";
            if (this.DuckImgLeft < -2.5) {
              this.DuckImgLeft = -2.5;
            } else {
              this.DuckImgLeft = this.DuckImgLeft - Math.abs(scroll) * 0.7;
            }
            DuckImg.style.transform =
                "translate(" +
                this.DuckImgLeft +
                "px" +
                "," +
                this.DuckImgTop +
                "px)" +
                " " +
                "scale(" +
                this.DuckImgscale +
                ")";
          } else if (this.DuckImgTop >= 620) {
            if (this.DuckImgTop > 730) {
              this.DuckImgscale = this.DuckImgscale + Math.abs(scroll) * 0.02;
              this.VillainRedscale =
                  this.VillainRedscale + Math.abs(scroll) * 0.02;
              this.VillainRedrotate =
                  this.VillainRedrotate - Math.abs(scroll) * 6;
              this.VillainRedLeft = this.VillainRedLeft - Math.abs(scroll) * 1;
              this.VillainRedTop = this.VillainRedTop - Math.abs(scroll) * 0.5;
              this.VillainBluescale =
                  this.VillainBluescale + Math.abs(scroll) * 0.02;
              this.VillainBluerotate =
                  this.VillainBluerotate - -(Math.abs(scroll) * 10);
              this.VillainBlueLeft =
                  this.VillainBlueLeft + Math.abs(scroll) * 1;
              this.VillainBlueTop =
                  this.VillainBlueTop - Math.abs(scroll) * 0.5;
              if (this.DuckImgscale >= 1) {
                this.DuckImgscale = 1;
              } else {
                this.DuckImgscale = this.DuckImgscale;
              }
              if (this.VillainRedscale >= 1) {
                this.VillainRedscale = 1;
              } else {
                this.VillainRedscale = this.VillainRedscale;
              }
              if (this.VillainRedLeft <= 0) {
                this.VillainRedLeft = 0;
              } else {
                this.VillainRedLeft = this.VillainRedLeft;
              }
              if (this.VillainRedTop <= 0) {
                this.VillainRedTop = 0;
              } else {
                this.VillainRedTop = this.VillainRedTop;
              }

              if (this.VillainBluescale >= 1) {
                this.VillainBluescale = 1;
              } else {
                this.VillainBluescale = this.VillainBluescale;
              }
              if (this.VillainBlueLeft >= 0) {
                this.VillainBlueLeft = 0;
              } else {
                this.VillainBlueLeft = this.VillainBlueLeft;
              }
              if (this.VillainBlueTop <= 0) {
                this.VillainBlueTop = 0;
              } else {
                this.VillainBlueTop = this.VillainBlueTop;
              }

              DuckImg.style.transform =
                  "translate(" +
                  this.DuckImgLeft +
                  "px" +
                  "," +
                  this.DuckImgTop +
                  "px)" +
                  " " +
                  "scale(" +
                  this.DuckImgscale +
                  ")";
              VillainRed.style.transform =
                  "translate(" +
                  this.VillainRedLeft +
                  "px" +
                  "," +
                  this.VillainRedTop +
                  "px)" +
                  " " +
                  "rotate(" +
                  this.VillainRedrotate +
                  "deg)" +
                  " " +
                  "scale(" +
                  this.VillainRedscale +
                  ")";

              VillainBlue.style.transform =
                  "translate(" +
                  this.VillainBlueLeft +
                  "px" +
                  "," +
                  this.VillainBlueTop +
                  "px)" +
                  " " +
                  "rotate(" +
                  this.VillainBluerotate +
                  "deg)" +
                  " " +
                  "scale(" +
                  this.VillainBluescale +
                  ")";
            } else {
              this.DuckImgscale = 1;
              this.VillainRedscale = 1;
              this.VillainRedrotate = 0;
              this.VillainRedLeft = 0;
              this.VillainRedTop = 0;
              this.VillainBluescale = 1;
              this.VillainBluerotate = 0;
              this.VillainBlueLeft = 0;
              this.VillainBlueTop = 0;
              DuckImg.style.transform =
                  "translate(" +
                  this.DuckImgLeft +
                  "px" +
                  "," +
                  this.DuckImgTop +
                  "px)" +
                  " " +
                  "scale(" +
                  this.DuckImgscale +
                  ")";
              VillainRed.style.transform =
                  "translate(" +
                  this.VillainRedLeft +
                  "px" +
                  "," +
                  this.VillainRedTop +
                  "px)" +
                  " " +
                  "rotate(" +
                  this.VillainRedrotate +
                  "deg)" +
                  " " +
                  "scale(" +
                  this.VillainRedscale +
                  ")";
              VillainBlue.style.transform =
                  "translate(" +
                  this.VillainBlueLeft +
                  "px" +
                  "," +
                  this.VillainBlueTop +
                  "px)" +
                  " " +
                  "rotate(" +
                  this.VillainBluerotate +
                  "deg)" +
                  " " +
                  "scale(" +
                  this.VillainBluescale +
                  ")";
            }
          }
        } else {
          var evennamber = Math.trunc(this.i / 75);
          if (evennamber % 2 == 0) {
            this.direction = true;
          } else {
            this.direction = false;
          }
          if (this.DuckImgTop < 27) {
            this.DuckImgTop = this.DuckImgTop + Math.abs(scroll) * 1.4;
            this.DuckImgLeft = 0;
            DuckImg.style.transform =
                "translate(" +
                this.DuckImgLeft +
                "px" +
                "," +
                this.DuckImgTop +
                "px)" +
                " " +
                "scale(" +
                this.DuckImgscale +
                ")";
            this.VillainRedscale = 1;
            this.VillainRedrotate = 0;
            this.VillainRedLeft = 0;
            this.VillainRedTop = 0;
            this.VillainBluescale = 1;
            this.VillainBluerotate = 0;
            this.VillainBlueLeft = 0;
            this.VillainBlueTop = 0;
            VillainRed.style.transform =
                "translate(" +
                this.VillainRedLeft +
                "px" +
                "," +
                this.VillainRedTop +
                "px)" +
                " " +
                "rotate(" +
                this.VillainRedrotate +
                "deg)" +
                " " +
                "scale(" +
                this.VillainRedscale +
                ")";

            VillainBlue.style.transform =
                "translate(" +
                this.VillainBlueLeft +
                "px" +
                "," +
                this.VillainBlueTop +
                "px)" +
                " " +
                "rotate(" +
                this.VillainBluerotate +
                "deg)" +
                " " +
                "scale(" +
                this.VillainBluescale +
                ")";
          } else if (this.DuckImgTop >= 27 && this.DuckImgTop < 86) {
            this.VillainRedscale = 1;
            this.VillainRedrotate = 0;
            this.VillainRedLeft = 0;
            this.VillainRedTop = 0;
            this.VillainBluescale = 1;
            this.VillainBluerotate = 0;
            this.VillainBlueLeft = 0;
            this.VillainBlueTop = 0;
            VillainRed.style.transform =
                "translate(" +
                this.VillainRedLeft +
                "px" +
                "," +
                this.VillainRedTop +
                "px)" +
                " " +
                "rotate(" +
                this.VillainRedrotate +
                "deg)" +
                " " +
                "scale(" +
                this.VillainRedscale +
                ")";

            VillainBlue.style.transform =
                "translate(" +
                this.VillainBlueLeft +
                "px" +
                "," +
                this.VillainBlueTop +
                "px)" +
                " " +
                "rotate(" +
                this.VillainBluerotate +
                "deg)" +
                " " +
                "scale(" +
                this.VillainBluescale +
                ")";
            this.DuckImgTop = this.DuckImgTop + Math.abs(scroll) * 1.4;
            this.DuckImgLeft = this.DuckImgLeft + Math.abs(scroll) * 0.5;
            DuckImg.style.transform =
                "translate(" +
                this.DuckImgLeft +
                "px" +
                "," +
                this.DuckImgTop +
                "px)" +
                " " +
                "scale(" +
                this.DuckImgscale +
                ")";
          } else if (this.DuckImgTop >= 86 && this.DuckImgTop < 154) {
            this.VillainRedscale = 1;
            this.VillainRedrotate = 0;
            this.VillainRedLeft = 0;
            this.VillainRedTop = 0;
            this.VillainBluescale = 1;
            this.VillainBluerotate = 0;
            this.VillainBlueLeft = 0;
            this.VillainBlueTop = 0;
            VillainRed.style.transform =
                "translate(" +
                this.VillainRedLeft +
                "px" +
                "," +
                this.VillainRedTop +
                "px)" +
                " " +
                "rotate(" +
                this.VillainRedrotate +
                "deg)" +
                " " +
                "scale(" +
                this.VillainRedscale +
                ")";

            VillainBlue.style.transform =
                "translate(" +
                this.VillainBlueLeft +
                "px" +
                "," +
                this.VillainBlueTop +
                "px)" +
                " " +
                "rotate(" +
                this.VillainBluerotate +
                "deg)" +
                " " +
                "scale(" +
                this.VillainBluescale +
                ")";
            this.DuckImgTop = this.DuckImgTop + Math.abs(scroll) * 1.4;
            DuckImg.style.transform =
                "translate(" +
                this.DuckImgLeft +
                "px" +
                "," +
                this.DuckImgTop +
                "px)" +
                " " +
                "scale(" +
                this.DuckImgscale +
                ")";
          } else if (this.DuckImgTop >= 154 && this.DuckImgTop < 208) {
            this.VillainRedscale = 1;
            this.VillainRedrotate = 0;
            this.VillainRedLeft = 0;
            this.VillainRedTop = 0;
            this.VillainBluescale = 1;
            this.VillainBluerotate = 0;
            this.VillainBlueLeft = 0;
            this.VillainBlueTop = 0;
            VillainRed.style.transform =
                "translate(" +
                this.VillainRedLeft +
                "px" +
                "," +
                this.VillainRedTop +
                "px)" +
                " " +
                "rotate(" +
                this.VillainRedrotate +
                "deg)" +
                " " +
                "scale(" +
                this.VillainRedscale +
                ")";

            VillainBlue.style.transform =
                "translate(" +
                this.VillainBlueLeft +
                "px" +
                "," +
                this.VillainBlueTop +
                "px)" +
                " " +
                "rotate(" +
                this.VillainBluerotate +
                "deg)" +
                " " +
                "scale(" +
                this.VillainBluescale +
                ")";
            this.DuckImgTop = this.DuckImgTop + Math.abs(scroll) * 1.4;
            this.DuckImgLeft = this.DuckImgLeft - Math.abs(scroll) * 0.5;
            DuckImg.style.transform =
                "translate(" +
                this.DuckImgLeft +
                "px" +
                "," +
                this.DuckImgTop +
                "px)" +
                " " +
                "scale(" +
                this.DuckImgscale +
                ")";
          } else if (this.DuckImgTop >= 208 && this.DuckImgTop < 582) {
            this.VillainRedscale = 1;
            this.VillainRedrotate = 0;
            this.VillainRedLeft = 0;
            this.VillainRedTop = 0;
            this.VillainBluescale = 1;
            this.VillainBluerotate = 0;
            this.VillainBlueLeft = 0;
            this.VillainBlueTop = 0;
            VillainRed.style.transform =
                "translate(" +
                this.VillainRedLeft +
                "px" +
                "," +
                this.VillainRedTop +
                "px)" +
                " " +
                "rotate(" +
                this.VillainRedrotate +
                "deg)" +
                " " +
                "scale(" +
                this.VillainRedscale +
                ")";

            VillainBlue.style.transform =
                "translate(" +
                this.VillainBlueLeft +
                "px" +
                "," +
                this.VillainBlueTop +
                "px)" +
                " " +
                "rotate(" +
                this.VillainBluerotate +
                "deg)" +
                " " +
                "scale(" +
                this.VillainBluescale +
                ")";
            this.DuckImgTop = this.DuckImgTop + Math.abs(scroll) * 1.4;
            DuckImg.style.transform =
                "translate(" +
                this.DuckImgLeft +
                "px" +
                "," +
                this.DuckImgTop +
                "px)" +
                " " +
                "scale(" +
                this.DuckImgscale +
                ")";
          } else if (this.DuckImgTop >= 582 && this.DuckImgTop < 620) {
            this.VillainRedscale = 1;
            this.VillainRedrotate = 0;
            this.VillainRedLeft = 0;
            this.VillainRedTop = 0;
            this.VillainBluescale = 1;
            this.VillainBluerotate = 0;
            this.VillainBlueLeft = 0;
            this.VillainBlueTop = 0;
            VillainRed.style.transform =
                "translate(" +
                this.VillainRedLeft +
                "px" +
                "," +
                this.VillainRedTop +
                "px)" +
                " " +
                "rotate(" +
                this.VillainRedrotate +
                "deg)" +
                " " +
                "scale(" +
                this.VillainRedscale +
                ")";

            VillainBlue.style.transform =
                "translate(" +
                this.VillainBlueLeft +
                "px" +
                "," +
                this.VillainBlueTop +
                "px)" +
                " " +
                "rotate(" +
                this.VillainBluerotate +
                "deg)" +
                " " +
                "scale(" +
                this.VillainBluescale +
                ")";
            this.DuckImgTop = this.DuckImgTop + Math.abs(scroll) * 1.4;
            if (this.DuckImgLeft > 20) {
              this.DuckImgLeft = 20;
            } else {
              this.DuckImgLeft = this.DuckImgLeft + Math.abs(scroll) * 0.7;
            }
            DuckImg.style.transform =
                "translate(" +
                this.DuckImgLeft +
                "px" +
                "," +
                this.DuckImgTop +
                "px)" +
                " " +
                "scale(" +
                this.DuckImgscale +
                ")";
          } else if (this.DuckImgTop >= 620) {
            if (this.DuckImgTop > 790) {
              this.DuckImgTop = 790;
            } else {
              this.DuckImgTop = this.DuckImgTop + Math.abs(scroll) * 1.8;
            }
            if (this.DuckImgTop > 730) {
              this.DuckImgscale = this.DuckImgscale - Math.abs(scroll) * 0.02;
              this.VillainRedscale =
                  this.VillainRedscale - Math.abs(scroll) * 0.02;
              this.VillainRedrotate =
                  this.VillainRedrotate + Math.abs(scroll) * 6;
              this.VillainRedLeft = this.VillainRedLeft + Math.abs(scroll) * 1;
              this.VillainRedTop = this.VillainRedTop + Math.abs(scroll) * 0.5;
              this.VillainBluescale =
                  this.VillainBluescale - Math.abs(scroll) * 0.02;
              this.VillainBluerotate =
                  this.VillainBluerotate + -(Math.abs(scroll) * 10);
              this.VillainBlueLeft =
                  this.VillainBlueLeft - Math.abs(scroll) * 1;
              this.VillainBlueTop =
                  this.VillainBlueTop + Math.abs(scroll) * 0.5;
              if (this.DuckImgscale <= 0) {
                this.DuckImgscale = 0;
              } else {
                this.DuckImgscale = this.DuckImgscale;
              }
              if (this.VillainRedscale <= 0) {
                this.VillainRedscale = 0;
              } else {
                this.VillainRedscale = this.VillainRedscale;
              }
              if (this.VillainBluescale <= 0) {
                this.VillainBluescale = 0;
              } else {
                this.VillainBluescale = this.VillainBluescale;
              }
              if (this.VillainRedLeft >= 58) {
                this.VillainRedLeft = 58;
              } else {
                this.VillainRedLeft = this.VillainRedLeft;
              }
              if (this.VillainRedTop >= 32) {
                this.VillainRedTop = 32;
              } else {
                this.VillainRedTop = this.VillainRedTop;
              }

              if (this.VillainBlueLeft <= -58) {
                this.VillainBlueLeft = -58;
              } else {
                this.VillainBlueLeft = this.VillainBlueLeft;
              }
              if (this.VillainBlueTop >= 32) {
                this.VillainBlueTop = 32;
              } else {
                this.VillainBlueTop = this.VillainBlueTop;
              }
              DuckImg.style.transform =
                  "translate(" +
                  this.DuckImgLeft +
                  "px" +
                  "," +
                  this.DuckImgTop +
                  "px)" +
                  " " +
                  "scale(" +
                  this.DuckImgscale +
                  ")";
              VillainRed.style.transform =
                  "translate(" +
                  this.VillainRedLeft +
                  "px" +
                  "," +
                  this.VillainRedTop +
                  "px)" +
                  " " +
                  "rotate(" +
                  this.VillainRedrotate +
                  "deg)" +
                  " " +
                  "scale(" +
                  this.VillainRedscale +
                  ")";
              VillainBlue.style.transform =
                  "translate(" +
                  this.VillainBlueLeft +
                  "px" +
                  "," +
                  this.VillainBlueTop +
                  "px)" +
                  " " +
                  "rotate(" +
                  this.VillainBluerotate +
                  "deg)" +
                  " " +
                  "scale(" +
                  this.VillainBluescale +
                  ")";
            } else {
              this.DuckImgscale = 1;
              this.VillainRedscale = 1;
              this.VillainRedrotate = 0;
              this.VillainRedLeft = 0;
              this.VillainRedTop = 0;
              this.VillainBluescale = 1;
              this.VillainBluerotate = 0;
              this.VillainBlueLeft = 0;
              this.VillainBlueTop = 0;
              DuckImg.style.transform =
                  "translate(" +
                  this.DuckImgLeft +
                  "px" +
                  "," +
                  this.DuckImgTop +
                  "px)" +
                  " " +
                  "scale(" +
                  this.DuckImgscale +
                  ")";
              VillainRed.style.transform =
                  "translate(" +
                  this.VillainRedLeft +
                  "px" +
                  "," +
                  this.VillainRedTop +
                  "px)" +
                  " " +
                  "rotate(" +
                  this.VillainRedrotate +
                  "deg)" +
                  " " +
                  "scale(" +
                  this.VillainRedscale +
                  ")";
              VillainBlue.style.transform =
                  "translate(" +
                  this.VillainBlueLeft +
                  "px" +
                  "," +
                  this.VillainBlueTop +
                  "px)" +
                  " " +
                  "rotate(" +
                  this.VillainBluerotate +
                  "deg)" +
                  " " +
                  "scale(" +
                  this.VillainBluescale +
                  ")";
            }
          }
        }
      } else if (this.i >= this.meteorDom-450) {
        this.DuckImgTop = 790;
        this.DuckImgLeft = 20;
        this.DuckImgscale = 0;
        this.VillainRedrotate = 0;
        this.VillainRedscale = 0;
        this.VillainRedLeft = 58;
        this.VillainRedTop = 32;
        this.VillainBluerotate = 0;
        this.VillainBluescale = 0;
        this.VillainBlueLeft = -58;
        this.VillainBlueTop = 32;
        VillainRed.style.transform =
            "translate(" +
            this.VillainRedLeft +
            "px" +
            "," +
            this.VillainRedTop +
            "px)" +
            " " +
            "rotate(" +
            this.VillainRedrotate +
            "deg)" +
            " " +
            "scale(" +
            this.VillainRedscale +
            ")";
        DuckImg.style.transform =
            "translate(" +
            this.DuckImgLeft +
            "px" +
            "," +
            this.DuckImgTop +
            "px)" +
            " " +
            "scale(" +
            this.DuckImgscale +
            ")";
        VillainBlue.style.transform =
            "translate(" +
            this.VillainBlueLeft +
            "px" +
            "," +
            this.VillainBlueTop +
            "px)" +
            " " +
            "rotate(" +
            this.VillainBluerotate +
            "deg)" +
            " " +
            "scale(" +
            this.VillainBluescale +
            ")";
      }
    },
    road_del1(e) {
      if (e == 1) {
        if (this.decipherment) {
          this.road1_kutitle = "Transformation";
          this.road1_kutitle1 = "Manual";
          this.road1_kutext2 =
              "- Delete your pop playlist. In fact throw away that iPhone. Heck, stomp on it just to be safe.";
          this.road1_kutext3 =
              "- Apply strong eye drops at virtual concerts to maintain maximum level of eye-opening duration.";
          this.road1_kutext4 = "- Don't be a dick. Be a dude.";
          this.road1_kutext =
              "- Take off your boring clothes, burn them if necessary. Our attributes are way much cooler.";
        } else {
          this.road1_kutitle = "蜕变秘籍";
          this.road1_kutitle1 = "";
          this.road1_kutext2 =
              "· 脱掉那些无聊的衣服，必要时烧掉。 不为啥，只想告诉你们，我们手里的货要酷得多。";
          this.road1_kutext3 =
              "· 删除掉您的流行播放列表。害，直接扔掉那部 iPhone 算逑，再给它补上几脚，以防万一。";
          this.road1_kutext4 = "·  别去当个傻der。 做个酷哥多带劲。";
          this.road1_kutext =
              "· 在虚拟音乐会上滴上几滴强效眼药水，以保持最大程度的睁眼持续时间。";
        }
        this.road_cha = "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/road_cha3.png";
        this.biaozhu = false;
      } else if (e == 2) {
        if (this.decipherment) {
          this.road1_kutext2 = "";
          this.road1_kutext3 = "";
          this.road1_kutext4 = "";
          this.road1_kutitle = "Service Provider:";
          this.road1_kutitle1 = "OHDAT Labs";
          this.road1_kutext =
              "OHDAT Labs partners with top creators and intellectual properties to build Web3 ecosystems for them through creativity, technology, art, and storytelling. Check out OHDAT Genesis Pass. It guarantees a Peking Monsters whitelist. ";
        } else {
          this.road1_kutext2 = "";
          this.road1_kutext3 = "";
          this.road1_kutext4 = "";
          this.road1_kutitle = "旅程服务商：";
          this.road1_kutitle1 = "OHDAT Labs";
          this.road1_kutext =
              "OHDAT Labs 旨在与顶级创作者们和知识产权方合作，通过创意、技术、艺术与故事为他们构建 Web3 的生态系统。作为 OHDAT Genesis Pass的持有者，您铁定能进后台了！";
        }
        this.road_cha = "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/road_cha4.png";
        this.biaozhu = true;
      } else if (e == 3) {
        if (this.decipherment) {
          this.road1_kutext2 = "";
          this.road1_kutext3 = "";
          this.road1_kutext4 = "";
          this.road1_kutitle = "Peking Monsters";
          this.road1_kutitle1 = "Express";
          this.road1_kutext =
              "Testing, 123, this is your trip guide speaking. We are a party of 2777 monsters heading to the Rock³verse in the Ethereum blockchain. The world may be in lockdown, but the show DOES NOT stop, so unbuckle those safety belts, chug that Remaining alcohol, and put on your predator sunglasses. It's gonna be a rough rough ride.";
        } else {
          this.road1_kutext2 = "";
          this.road1_kutext3 = "";
          this.road1_kutext4 = "";
          this.road1_kutitle = "列车";
          this.road1_kutitle1 = "北海怪兽号";
          this.road1_kutext =
              "喂？喂，123，我是你们本趟旅程的向导。 我们是2777个怪兽，前方目的地：以太坊区块链中的摇滚宇宙站。无论你身处的世界是否还处于严肃的封锁状态，在这里，表演与狂欢将永不停歇。解开安全带吧，把剩余的酒精速速清场，戴上你们专属的顶猎太阳镜。 充满挑战的旅途即将启程！";
        }
        this.road_cha = "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/road_cha5.png";
        this.biaozhu = false;
      } else if (e == 4) {
        if (this.decipherment) {
          this.road1_kutext2 = "";
          this.road1_kutext3 = "";
          this.road1_kutext4 = "";
          this.road1_kutitle = "Trip Guide:";
          this.road1_kutitle1 = "Peng Lei";
          this.road1_kutext =
              "Peng Lei, cartoonist, director, vocalist and guitarist of New Pants, author of graphic novel Peking Monster. With a strong love for high contrast and genre intermixture, he underlines his notion of an idealistic world beneath realistic constructions. Same as his ever eccentric works, this project is a blender of whirling visual and audio sensations.";
        } else {
          this.road1_kutext2 = "";
          this.road1_kutext3 = "";
          this.road1_kutext4 = "";
          this.road1_kutitle = " 旅途向导：";
          this.road1_kutitle1 = "彭磊";
          this.road1_kutext =
              "彭磊，漫画家、导演、歌手、吉他手，漫画小说《北海怪兽》作者。用对比强烈的色彩、粗粒的线条和戏剧化的情节，他向我们描绘了一个现实桎梏中的理想世界。 与他以往怪癖的作品一样，这是一个为你带来视觉和听觉双重冲击的艺术系列。";
        }
        this.road_cha = "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/road_cha2.png";
        this.biaozhu = false;
      } else if (e == 5) {
        if (this.decipherment) {
          this.road1_kutext2 = "";
          this.road1_kutext3 = "";
          this.road1_kutext4 = "";
          this.road1_kutitle = "Seating Plan";
          this.road1_kutitle1 = "";
          this.road1_kutext =
              "2777 total supply, 255 withhold by team. 777 Backstage Lists with 308 for OHDAT Genesis Pass holders, 1745 for public sale. 777+X signed editions in total.";
        } else {
          this.road1_kutext2 = "";
          this.road1_kutext3 = "";
          this.road1_kutext4 = "";
          this.road1_kutitle = "列车座位表";
          this.road1_kutitle1 = "";
          this.road1_kutext =
              "2777的总供应量，其中的255个由团队保留。在2777个怪兽中，有777 + X个将会是带有艺术家彭磊独家签名的限量版，其中308个会留给我们尊贵的OHDAT Genesis Pass牛逼持有者，469个我们会在后台门口挨个发放，其余的供应量将会分配到公售阶段。自个儿算，忘掉教你数学的那位体育老师吧。";
        }
        this.road_cha = "https://img.ohdat.io/peking_monsters/website/assets/icon/mint/road_cha1.png";
        this.biaozhu = false;
      }
      this.road1_ku = true;
    },
    highlightall(e) {
      if (e == 1) {
        if (this.decipherment) {
          this.roadmap_kutitle = "IRL";
          this.roadmap_kutitle1 = "";
          this.roadmap_kutext =
              "The Rock³verse experience reaches out to the physical world. Our series of offline activities endow holders with numerous offline benefits including exclusive access to Peng’s pop toy collectibles and concert ticket airdrops.";
        } else {
          this.roadmap_kutitle = "带WEB2一起燥";
          this.roadmap_kutitle1 = "";
          this.roadmap_kutext =
              "Rock³verse 的赋能体验将延伸至现实世界。 我们的线下活动系列为持有者们提供了众多线下福利，包括获得彭磊独家的潮玩和音乐会门票空投。";
        }
      } else if (e == 2) {
        if (this.decipherment) {
          this.roadmap_kutitle = "ART INSTALLATION";
          this.roadmap_kutitle1 = "";
          this.roadmap_kutext =
              "Introducing online/offline art installations to expand the possibilities of Rock³verse. Customized gateways to facilitate more elaborate exhibitions of art derivatives from top global brands.";
        } else {
          this.roadmap_kutitle = "艺术延展";
          this.roadmap_kutitle1 = "";
          this.roadmap_kutext =
              "引入在线与离线共同支持的艺术展馆，扩展 Rock³verse 的可塑性。 用完全自定义的方式，与最顶级的品牌和艺术站在一起。";
        }
      } else if (e == 3) {
        if (this.decipherment) {
          this.roadmap_kutitle = "ROCK³VERSE";
          this.roadmap_kutitle1 = "";
          this.roadmap_kutext =
              "A utopian wonderland created by OHDAT for the likes of Peng Lei, his artist friends, and any soul that isn't afraid to take a walk on the wild side.";
        } else {
          this.roadmap_kutitle = "欢迎来到ROCK³VERSE";
          this.roadmap_kutitle1 = "";
          this.roadmap_kutext =
              "拥抱真诚和自由，这里是一个由 OHDAT 为彭磊、他的艺术家朋友们以及所有不羁的灵魂创造的乌托邦摇滚仙境。";
        }
      } else if (e == 4) {
        if (this.decipherment) {
          this.roadmap_kutitle1 = "More to come...";
          this.roadmap_kutitle = "";
          this.roadmap_kutext = "";
        } else {
          this.roadmap_kutitle1 = "更尖儿的货还在路上...";
          this.roadmap_kutitle = "";
          this.roadmap_kutext = "";
        }
      } else if (e == 5) {
        if (this.decipherment) {
          this.roadmap_kutitle = "TIME MACHINE";
          this.roadmap_kutitle1 = "";
          this.roadmap_kutext =
              "Rock³verse is set in this world, but where's the fun in using only one layer in a soundtrack? Explore the multiverse, find other rock artists, and add more tracks to this rocking experience of a lifetime.";
        } else {
          this.roadmap_kutitle = "时光机";
          this.roadmap_kutitle1 = "";
          this.roadmap_kutext =
              "Rock³verse 已在本世界中准备就绪，但在原声带中如果只能使用单一的位面，那还有什么意思呢？ 探索多重宇宙，与更多摇滚艺术家一起燥，这，才是我们的使命。";
        }
      } else if (e == 6) {
        if (this.decipherment) {
          this.roadmap_kutitle = "VIRTUAL CONCERT";
          this.roadmap_kutitle1 = "";
          this.roadmap_kutext =
              "Shades down, bottoms up. No need to elbow your way through the crowd because here at Rock³verse Virtual Concerts, every holder's in the front row. Vibe, hands high, socialize.";
        } else {
          this.roadmap_kutitle = "虚拟音乐节现场";
          this.roadmap_kutitle1 = "";
          this.roadmap_kutext =
              "阴影向下，由下至上。 无需挤过人群，因为在 Rock³verse 虚拟音乐会上，每位闪耀的北海怪兽持有者都会在前排。氛围搞起来，双手举高，大胆社交！";
        }
      } else if (e == 7) {
        if (this.decipherment) {
          this.roadmap_kutitle = "COMMUNITY AND PERKS";
          this.roadmap_kutitle1 = "";
          this.roadmap_kutext =
              "The frontier of the Rock³verse is expanding as we speak with the reconstruction of characters from the Peking Monster novel. Holders will have first access to whitelisting future collections.";
        } else {
          this.roadmap_kutitle = "无拘无束的社区";
          this.roadmap_kutitle1 = "";
          this.roadmap_kutext =
              "当我们在定义着WEB3全新版本的北海怪兽时，Rock³verse的边界也在逐步扩大。未来更多收藏系列中，北海怪兽持有者将首先获得白名单权利。";
        }
      } else if (e == 8) {
        if (this.decipherment) {
          this.roadmap_kutitle1 = "More to come...";
          this.roadmap_kutitle = "";
          this.roadmap_kutext = "";
        } else {
          this.roadmap_kutitle1 = "更尖儿的货还在路上...";
          this.roadmap_kutitle = "";
          this.roadmap_kutext = "";
        }
      } else if (e == 9) {
        if (this.decipherment) {
          this.roadmap_kutitle1 = "More to come...";
          this.roadmap_kutitle = "";
          this.roadmap_kutext = "";
        } else {
          this.roadmap_kutitle1 = "更尖儿的货还在路上...";
          this.roadmap_kutitle = "";
          this.roadmap_kutext = "";
        }
      } else if (e == 10) {
        if (this.decipherment) {
          this.roadmap_kutitle1 = "More to come...";
          this.roadmap_kutitle = "";
          this.roadmap_kutext = "";
        } else {
          this.roadmap_kutitle1 = "更尖儿的货还在路上...";
          this.roadmap_kutitle = "";
          this.roadmap_kutext = "";
        }
      } else if (e == 11) {
        if (this.decipherment) {
          this.roadmap_kutitle1 = "More to come...";
          this.roadmap_kutitle = "";
          this.roadmap_kutext = "";
        } else {
          this.roadmap_kutitle1 = "更尖儿的货还在路上...";
          this.roadmap_kutitle = "";
          this.roadmap_kutext = "";
        }
      }
      this.roadmap_ku = true;
    },
    close_record() {
      this.road1_ku = false;
      this.roadmap_ku = false;
      this.mintbox = false;
      this.Afterclicking = false;
      this.viewonop = false;
      this.Notclickable = true;
      this.checkbox = false;
      this.inquire = false;
      this.inquireText = "";
      this.inquireRemaining = 0;
      this.passxia = false;
      // this.getGoodsDatail();
      // this.getPurchaseOptions();
    },
    close_record1() {
      this.PassKa = false;
    },
    passKaTrue() {
      this.PassKa = true;
    },
    imgxuanze(e) {
      this.pass_id = e;
      this.close_record1();
      this.getPurchaseOptions();
    },
    miao(e) {
      document.querySelector(e).scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    },
    miao1(e) {
      document.querySelector(e).scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    },
    revise() {
      this.revise_text = !this.revise_text;
    },
    deciphermented() {
      this.decipherment = !this.decipherment;
    },
    highM() {
      clearInterval(time);
      var time = setInterval(() => {
        var min = 0;
        var max = 10;
        var i = Math.floor(Math.random() * (max - min + 1)) + min;
        this.ii = this.highList[i].id;
      }, 500);
    },
    menu() {
      this.menudel = !this.menudel;
    },
    async logIn() {
      let currentAccount;
      try {
        currentAccount = await getCurrentAccount();
      } catch (error) {
        var herf = window.location.hostname;
        window.open("https://metamask.app.link/dapp/" + herf);
        // alert(;"失败")
      }
      if (currentAccount == null) {
        await connectWallet();
      }
      this.connected = true;
      this.whitelisttr();
      // if(this.$refs.lights != undefined){
      //     this.$refs.lights.classList.remove("lightsdong");
      // }
    },
    async checkLogIn() {
      let currentAccount = await getCurrentAccount();
      console.log("currentAccount", currentAccount);
      if (currentAccount != undefined) {
        this.walletAddress =
            currentAccount.substr(0, 5) + "****" + currentAccount.substr(-4);
      }
      if (currentAccount) {
        this.connected = true;
        this.whitelisttr();

        if (localStorage.getItem("wallet_address") != currentAccount) {
          localStorage.setItem("wallet_address", currentAccount);
        }
        // let tokenNow = localStorage.getItem("token_" + currentAccount);
        // // console.log(tokenNow);
        // if (tokenNow) {
        await this.getPurchaseOptions();
        // }
        // if(this.$refs.lights != undefined){
        //   this.$refs.lights.classList.remove("lightsdong");
        // }
      }
    },
    async mintSign(e) {
      let currentAccount = await getCurrentAccount();
      if (currentAccount == null) {
        await connectWallet();
        this.connected = true;
        this.whitelisttr();
      }
      let newToken = await walletSign(currentAccount);
      if (newToken) {
        localStorage.setItem("token_" + currentAccount, newToken);
        // await this.getGoodsDatail();
        // await this.getPurchaseOptions();
        if (e == 1) {
          this.whitelisttr();
        } else {
          if (this.status == 4 && this.available == 0) {
            // alert(66666)
          } else if (this.status == 5 && this.available == 0) {
            // alert(88888)
          } else {
            this.mintbox = true;
          }
        }
        // this.mintbox = true;
        // this.whitelisttr();
      }
    },
    async getGoodsDatail() {
      const params = {
        id: 8,
      };
      let res = await get_goods_datail(params);
      console.log(res);
      if (res.code == 200) {
        const {original_stock, stock, eth_price, status} = res.data;
        this.original_stock = original_stock;
        this.stock = stock;
        this.$store.commit("order/POST_GOODS_INFO", res.data);
        this.price = eth_price;
        this.status = status;
      } else {
        console.log("res err", res);
      }
    },
    async getPurchaseOptions() {
      const params = {
        id: 8,
        pass_id: this.pass_id,
      };
      let res = await get_purchase_options(params);
      console.log(res);
      if (res.code === 200) {
        this.available = res.data.available;
        this.passimg = res.data.pass_id;
        this.pass_id = res.data.pass_id;
        this.pass_list = res.data.pass_list;
        // THIS.purchase_limit = res.data.purchase_limit;
        this.contract_method = res.data.contract_method;
        // this.haveToken = true;
        this.limit = res.data.purchase_limit;
        if (this.limit != 0) {
          this.limitbig = this.limit;
        } else {
          this.limitbig = 10;
        }
        this.ethPrice = web3.utils.fromWei(res.data.price + "", "ether");

        // if (this.tannum == 0) {
        this.tannum = this.limit;
        // }
        // console.log(this.tannum);
        // console.log(this.ethPrice);
        this.totalPrice = (this.ethPrice * this.tannum).toFixed(1);
        if (this.totalPrice == 0.0) {
          this.totalPrice = 0;
        }
        // console.log(this.totalPrice);
      } else if (
          res.code === 1101 ||
          res.code === 1102 ||
          res.code === 1103 ||
          res.code === 1104 ||
          res.code === 1105
      ) {
        // localStorage.removeItem(
        //   "token_" + localStorage.getItem("wallet_address")
        // );
        // this.haveToken = false;
        this.available = 0;
        this.totalPrice = 0;
        this.limit = 0;
      }
    },
    //whiteListMint
    async whiteList() {
      await checkChain();
      let that = this;
      that.Notclickable = false;
      let formData = new FormData();
      console.log("totalPrice====" + this.totalPrice);
      console.log("quantity====" + this.tannum);
      formData.append("goods_id", "8");
      formData.append("quantity", this.tannum);
      let res = await place_goods_order(formData);
      if (res.code == 200) {
        console.log(res.data, "数据");
        let orderInfo = res.data;
        that.orderId = orderInfo["order_id"];
        whiteListMint(orderInfo)
            .on("transactionHash", function (hash) {
              that.Notclickable = false;
              that.Afterclicking = true;
              that.viewonop = false;
              // that.placeGoodsOrderSubmit();
              // that.showProceeding = true;
              // this.getGoodsDatail();
              // this.getPurchaseOptions();
            })
            .on("confirmation", function (confirmationNumber, receipt) {
              console.log("confirmationNumber", confirmationNumber);
            })
            .on("receipt", function (receipt) {
              that.Notclickable = false;
              that.viewonop = true;
              // that.showProceeding = false;
              // that.showOpensea = true;
              // that.status = 1;
              // that.available = 0;
              console.log(receipt, "receipt");
            })
            .on("error", function (error, receipt) {
              that.Notclickable = true;
              that.viewonop = false;
              // If the transaction was rejected by the network with a receipt, the second parameter will be the receipt.
              console.log(error);
            });
      } else {
        console.log("place_goods_order", "err");
      }
    },
    //passMint
    async passList() {
      await checkChain();
      let that = this;
      that.Notclickable = false;
      let formData = new FormData();
      console.log("totalPrice====" + this.totalPrice);
      console.log("quantity====" + this.tannum);
      formData.append("goods_id", "8");
      formData.append("quantity", this.tannum);
      formData.append("pass_id", this.pass_id);
      let res = await place_goods_order(formData);
      if (res.code == 200) {
        console.log(res.data, "数据");
        let orderInfo = res.data;
        that.orderId = orderInfo["order_id"];
        passMint(orderInfo)
            .on("transactionHash", function (hash) {
              that.Notclickable = false;
              that.Afterclicking = true;
              that.viewonop = false;
              // that.placeGoodsOrderSubmit();
              // that.showProceeding = true;
              // this.getGoodsDatail();
              // this.getPurchaseOptions();
            })
            .on("confirmation", function (confirmationNumber, receipt) {
              console.log("confirmationNumber", confirmationNumber);
            })
            .on("receipt", function (receipt) {
              that.Notclickable = false;
              that.viewonop = true;
              // that.showProceeding = false;
              // that.showOpensea = true;
              // that.status = 1;
              // that.available = 0;
              console.log(receipt, "receipt");
            })
            .on("error", function (error, receipt) {
              that.Notclickable = true;
              that.viewonop = false;
              // If the transaction was rejected by the network with a receipt, the second parameter will be the receipt.
              console.log(error);
            });
      } else {
        console.log("place_goods_order", "err");
      }
    },
    //Mint
    async mintList() {
      await checkChain();
      let that = this;
      that.Notclickable = false;
      let formData = new FormData();
      console.log("totalPrice====" + this.totalPrice);
      console.log("quantity====" + this.tannum);
      formData.append("goods_id", "8");
      formData.append("quantity", this.tannum);
      let res = await place_goods_order(formData);
      if (res.code == 200) {
        console.log(res.data, "数据");
        let orderInfo = res.data;
        that.orderId = orderInfo["order_id"];
        Mint(orderInfo)
            .on("transactionHash", function (hash) {
              that.Notclickable = false;
              that.Afterclicking = true;
              that.viewonop = false;
              // that.placeGoodsOrderSubmit();
              // that.showProceeding = true;
              // this.getGoodsDatail();
              // this.getPurchaseOptions();
            })
            .on("confirmation", function (confirmationNumber, receipt) {
              console.log("confirmationNumber", confirmationNumber);
            })
            .on("receipt", function (receipt) {
              that.Notclickable = false;
              that.viewonop = true;
              // that.showProceeding = false;
              // that.showOpensea = true;
              // that.status = 1;
              // that.available = 0;
              console.log(receipt, "receipt");
            })
            .on("error", function (error, receipt) {
              that.Notclickable = true;
              that.viewonop = false;
              // If the transaction was rejected by the network with a receipt, the second parameter will be the receipt.
              console.log(error);
            });
      } else {
        console.log("place_goods_order", "err");
      }
    },
    async placeGoodsOrderSubmit() {
      let formData = new FormData();
      formData.append("order_id", this.orderId);
      let res = await place_goods_order_submit(formData);
      console.log("placeGoodsOrderSubmit", res);
      if (res.code == 200) {
        console.log("place_goods_order_sbmit", "true");
        // this.$store.commit('mask/changeIndex', 1)
      } else {
        console.log("place_goods_order_sbmit", "err");
      }
    },
    mouseAddress() {
      this.hoverAddress = !this.hoverAddress;
    },
    watchWeb3Event() {
      var that = this;

      ethereum.on("disconnect", (res) => {
        console.log("已断开网络", res);
      });
      ethereum.on("connect", function (res) {
        console.log("已连接到网络", res);
      });
      ethereum.on("accountsChanged", function (res) {
        // alert('res[0]:'+res[0])
        localStorage.setItem("wallet_address", res[0]);
        that.CHECK_MY_STATUS = -1;
        //quantity
        // that.$refs.mintBtn.classList.add("mintMar");
        // if (res[0]) {
        // } else {
        //   that.walletAddress = "";
        //   that.connected = false;
        //   that.$refs.lights.classList.add("lightsdong");
        // }
        if (res[0]) {
          that.walletAddress = res[0].substr(0, 5) + "****" + res[0].substr(-4);
          that.connected = true;
          that.whitelisttr();
          that.pass_id = -1;
          // if (localStorage.getItem("token_" + res[0])) {
          // that.getGoodsDatail();
          // that.getPurchaseOptions();
          // alert("改",this.available)
          // that.showOpensea = false;
          // that.showProceeding = false;
          // } else {
          //   // that.showProceeding = false;
          //   // that.showOpensea = false;
          //   // that.haveToken = false;
          //   that.available = 0;
          //   that.limit = 0;
          //   that.totalPrice = 0;
          // }
          // if(that.$refs.lights != undefined){
          //   that.$refs.lights.classList.remove("lightsdong");
          // }
        } else {
          // that.showProceeding = false;
          // that.showOpensea = false;
          that.connected = false;
          // that.haveToken = false;
          that.available = 0;
          that.limit = 0;
          that.walletAddress = "";
          that.totalPrice = 0;
          // that.$refs.lights.classList.add("lightsdong");
        }
      });
      ethereum.on("chainChanged", function (res) {
        const network = parseInt(res, 16);
        console.log(`networkID`, res, network);
        that.$store
            .dispatch("web3/set_network", network)
            .then((res) => {
              if (
                  network == process.env.VUE_APP_MAIN_NETWORK ||
                  network == process.env.VUE_APP_MAIN_NETWORK2
              ) {
                that.$store.dispatch("mask/closeMask", "mainNetwork");
                //  this.connectMetaMask()
                console.log("已切换到连接主网络");
                // window.location.reload();
              } else {
                that.$store.dispatch("mask/openMask", "mainNetwork");
              }
            })
            .catch((err) => {
            });
      });
      ethereum.on("message", (msg) => console.log(msg, 11111));
      // alert("成功");
    },
    twitter() {
      window.open("https://twitter.com/Peking_Monsters");
    },
    redirect() {
      window.open("https://discord-verify.pekingmonsters.xyz/v2/redirect");
    },
    openseaview() {
      window.open("https://opensea.io/collection/pekingmonsters");
    },
    terms() {
      window.open(
          "https://master.ohdat.io/peking_monsters/terms.pdf",
          "_blank"
      );
    },
    mouseover() {
      this.highlight_irl = true;
      this.highlight_art = false;
      this.highlight_music = false;
      this.highlight_verse = false;
      this.highlight_machine = false;
      this.highlight_fast = false;
      this.highlight_you = false;
      this.highlight_car = false;
    },
    mouseLeave() {
      this.highlight_irl = false;
      this.highlight_art = false;
      this.highlight_music = false;
      this.highlight_verse = false;
      this.highlight_machine = false;
      this.highlight_fast = false;
      this.highlight_you = false;
      this.highlight_car = false;
    },
    mouseover1() {
      this.highlight_irl = false;
      this.highlight_art = true;
      this.highlight_music = false;
      this.highlight_verse = false;
      this.highlight_machine = false;
      this.highlight_fast = false;
      this.highlight_you = false;
      this.highlight_car = false;
    },
    mouseLeave1() {
      this.highlight_irl = false;
      this.highlight_art = false;
      this.highlight_music = false;
      this.highlight_verse = false;
      this.highlight_machine = false;
      this.highlight_fast = false;
      this.highlight_you = false;
      this.highlight_car = false;
    },
    mouseover2() {
      this.highlight_irl = false;
      this.highlight_art = false;
      this.highlight_music = true;
      this.highlight_verse = false;
      this.highlight_machine = false;
      this.highlight_fast = false;
      this.highlight_you = false;
      this.highlight_car = false;
    },
    mouseLeave2() {
      this.highlight_irl = false;
      this.highlight_art = false;
      this.highlight_music = false;
      this.highlight_verse = false;
      this.highlight_machine = false;
      this.highlight_fast = false;
      this.highlight_you = false;
      this.highlight_car = false;
    },
    mouseover3() {
      this.highlight_irl = false;
      this.highlight_art = false;
      this.highlight_music = false;
      this.highlight_verse = true;
      this.highlight_machine = false;
      this.highlight_fast = false;
      this.highlight_you = false;
      this.highlight_car = false;
    },
    mouseLeave3() {
      this.highlight_irl = false;
      this.highlight_art = false;
      this.highlight_music = false;
      this.highlight_verse = false;
      this.highlight_machine = false;
      this.highlight_fast = false;
      this.highlight_you = false;
      this.highlight_car = false;
    },
    mouseover4() {
      this.highlight_irl = false;
      this.highlight_art = false;
      this.highlight_music = false;
      this.highlight_verse = false;
      this.highlight_machine = true;
      this.highlight_fast = false;
      this.highlight_you = false;
      this.highlight_car = false;
    },
    mouseLeave4() {
      this.highlight_irl = false;
      this.highlight_art = false;
      this.highlight_music = false;
      this.highlight_verse = false;
      this.highlight_machine = false;
      this.highlight_fast = false;
      this.highlight_you = false;
      this.highlight_car = false;
    },
    mouseover5() {
      this.highlight_irl = false;
      this.highlight_art = false;
      this.highlight_music = false;
      this.highlight_verse = false;
      this.highlight_machine = false;
      this.highlight_fast = true;
      this.highlight_you = false;
      this.highlight_car = false;
    },
    mouseLeave5() {
      this.highlight_irl = false;
      this.highlight_art = false;
      this.highlight_music = false;
      this.highlight_verse = false;
      this.highlight_machine = false;
      this.highlight_fast = false;
      this.highlight_you = false;
      this.highlight_car = false;
    },
    mouseover6() {
      this.highlight_irl = false;
      this.highlight_art = false;
      this.highlight_music = false;
      this.highlight_verse = false;
      this.highlight_machine = false;
      this.highlight_fast = false;
      this.highlight_you = true;
      this.highlight_car = false;
    },
    mouseLeave6() {
      this.highlight_irl = false;
      this.highlight_art = false;
      this.highlight_music = false;
      this.highlight_verse = false;
      this.highlight_machine = false;
      this.highlight_fast = false;
      this.highlight_you = false;
      this.highlight_car = false;
    },
    mouseover7() {
      this.highlight_irl = false;
      this.highlight_art = false;
      this.highlight_music = false;
      this.highlight_verse = false;
      this.highlight_machine = false;
      this.highlight_fast = false;
      this.highlight_you = false;
      this.highlight_car = true;
    },
    mouseLeave7() {
      this.highlight_irl = false;
      this.highlight_art = false;
      this.highlight_music = false;
      this.highlight_verse = false;
      this.highlight_machine = false;
      this.highlight_fast = false;
      this.highlight_you = false;
      this.highlight_car = false;
    },
    playMusic() {
      this.$refs.initAudio.play();
      // this.$refs.initAudio.currentTime=180
      this.stop = false;
      this.load = false;
      this.loadplay = false;
      this.startAction = true;
    },
    stopplay() {
      this.stop = true;
      this.$refs.initAudio.pause();
      this.startAction = false;
    },
    shou() {
      this.load = true;
    },
    addOpen() {
      this.load = false;
      this.loadplay = false;
    },
    up() {
      this.$refs.initAudio.pause();
      setTimeout(() => {
        this.stop = false;
        this.$refs.initAudio.play();
      }, 100);
      this.singerNames = "";
      this.singers = "";
      this.musicSrc = "";
      this.$refs.musicName.innerHTML = "";
      this.index--;
      if (this.index == -1) {
        this.index = this.musicArr.length - 1;
      }
      this.singerNames = this.musicArr[this.index].singerName;
      this.singers = this.musicArr[this.index].singer;
      this.musicSrc = this.musicArr[this.index].url;
      this.$refs.musicName.innerHTML =
          '<marquee behavior="scroll" scrollamount = 2 style="color: #BD8732;font-family: Mono-Regular;font-size:12px"><div style="color: #000;font-family: caonima-Regular;font-size:14px">' +
          this.singerNames +
          "</div><div>" +
          this.singers +
          "</div></marquee>";
    },
    next() {
      this.$refs.initAudio.pause();
      setTimeout(() => {
        this.stop = false;
        this.$refs.initAudio.play();
      }, 100);
      this.singerNames = "";
      this.singers = "";
      this.musicSrc = "";
      this.$refs.musicName.innerHTML = "";
      this.index++;
      if (this.index == this.musicArr.length) {
        this.index = 0;
      }
      this.singerNames = this.musicArr[this.index].singerName;
      this.singers = this.musicArr[this.index].singer;
      this.musicSrc = this.musicArr[this.index].url;

      this.$refs.musicName.innerHTML =
          '<marquee behavior="scroll" scrollamount = 2 style="color: #BD8732;font-family: Mono-Regular;font-size:12px"><div style="color: #000;font-family: caonima-Regular;font-size:14px">' +
          this.singerNames +
          "</div><div>" +
          this.singers +
          "</div></marquee>";
    },
  },
  destroyed () {
    if (window.screen.width < 1024) {
      // window.addEventListener("scroll", this.windowScrollM, true);
      window.removeEventListener('scroll', this.windowScrollM, true);
    } else {
      // window.addEventListener("scroll", this.windowScroll, true);
      window.removeEventListener('scroll', this.windowScroll, true);
    }
  } 
};
</script>

<style src="./index.scss" lang="scss" scoped></style>
<style src="./index.mobile.scss" lang="scss" scoped></style>
