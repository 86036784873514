import request from '@/utils/request'
import baseURL from '@/utils/baseURL'
// 物品详情
export function get_goods_datail(query) {
  return request({
    url: baseURL['goods'] + '/v2/goods/detail',
    method: 'get',
    params: query,
    // data
  })
}
export function get_purchase_options(query) {
  return request({
    url: baseURL['goods'] + '/v2/goods/purchase_options',
    method: 'get',
    params: query,
    // data
  })
}
// 检查白名单状态
export function check_wallet_status(query) {
  return request({
    url: baseURL['goods'] + '/v2/goods/check_wallet_status',
    method: 'get',
    params: query,
    // data
  })
} 

// 检查id白名单状态
export function check_pass_status(query) {
  return request({
    url: baseURL['goods'] + '/v2/goods/check_pass_status',
    method: 'get',
    params: query,
    // data
  })
} 

// 抽奖信息
export function peking_monsters_raffle_info() {
  return request({
    url: baseURL['goods'] + '/v2/peking_monsters_raffle/info',
    method: 'get',
    // data
  })
} 